<template>

    <div class="slider-hover-prevent">

        <showAt breakpoint="large">
            <FormDesktopView @created="onCreated" :bonus="bonus"/>
        </showAt>
        <showAt breakpoint="medium">
            <FormTabletView @created="onCreated" :bonus="bonus"/>
        </showAt>
        <showAt breakpoint="small">
            <FormMobileView @created="onCreated" :bonus="bonus"/>
        </showAt>

        <b-modal
            ref="modal"
            id="contract-details-modal"
            centered
            hide-footer
            @hide="modalWillHide"
        >
            <created-modal-content v-if="contract" :contract="contract" />
        </b-modal>

    </div>

</template>

<script>

    import FormDesktopView from "./form.desktop"
    import FormTabletView from "./form.tablet"
    import FormMobileView from "./form.mobile"

    import CreatedModalContent from "./modals/created"

    export default {

        data() {
            return {
                contract : null,

                bonus: null
            }
        },
        methods: {

             modalWillHide() {
                this.$router.replace({
                    name: 'user-contract-details',
                    params: {
                        id: this.contract.guuid
                    }
                });
            },

            onCreated( args ) {
                this.$request.get("contracts.get", {
                    id: args.guuid
                }).then( result => {
                    this.contract = result;

                    if (this.contract.invoice.type === 'card') {
                        this.$router.replace({
                            name: 'user-contract-details',
                            params: {
                                id: this.contract.guuid
                            }
                        });
                        window.open(`${process.env.VUE_APP_BASE_URL}payment-make/${this.contract.invoice.identificator}`, '_blank');
                    } else {
                        this.$refs.modal.show();
                    }
                });
            },

            getBonus() {
                this.$request.get("user.bonus")
                .then( result => {
                    this.bonus = result
                });
            }

        },
        components: {
            FormDesktopView,
            FormTabletView,
            FormMobileView,

            CreatedModalContent
        },
        watch: {

        },
        mounted() {
            this.getBonus()
        }

    }

</script>
