var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"entity"},[_c('b-form',{staticClass:"data-form",on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('b-card',{staticClass:"m-0",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"pl-0"},[_c('b-card-title',{staticClass:"text-black",staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.$t('forms.company-info')))])],1),_c('b-card-body',{staticClass:"p-0"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"CompanyName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t('forms.name-entity')))]),_c('v-select',{attrs:{"options":_vm.companies,"label":"value","clearable":false},on:{"search":_vm.fetchCompanies,"option:selected":_vm.companySelected},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.name.full_with_opf)+" ")]}}],null,true),model:{value:(_vm.company.details),callback:function ($$v) {_vm.$set(_vm.company, "details", $$v)},expression:"company.details"}},[_c('template',{slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('forms.labels.enter-name-entity'))+" ")])],2),_c('small',{staticClass:"text-danger font-small-1"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"CompanyName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t('forms.labels.gen-director')))]),_c('b-form-input',{attrs:{"placeholder":_vm.$t('forms.placeholders.gen-director')},model:{value:(_vm.company.director),callback:function ($$v) {_vm.$set(_vm.company, "director", $$v)},expression:"company.director"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"CompanyInn","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t('forms.labels.inn')))]),_c('v-select',{attrs:{"options":_vm.companies,"label":"value","clearable":false},on:{"search":_vm.fetchCompanies,"option:selected":_vm.companySelected},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.inn)+" ")]}}],null,true),model:{value:(_vm.company.details),callback:function ($$v) {_vm.$set(_vm.company, "details", $$v)},expression:"company.details"}},[_c('template',{slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('forms.labels.enter-inn-or-ip'))+" ")])],2)]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"CompanyOgrn","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t('forms.labels.psrn')))]),_c('v-select',{attrs:{"options":_vm.companies,"label":"value","clearable":false},on:{"search":_vm.fetchCompanies,"option:selected":_vm.companySelected},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.ogrn)+" ")]}}],null,true),model:{value:(_vm.company.details),callback:function ($$v) {_vm.$set(_vm.company, "details", $$v)},expression:"company.details"}},[_c('template',{slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('forms.placeholders.enter-psrn-or-ip'))+" ")])],2)]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"CompanyKpp","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t('forms.labels.iec')))]),_c('v-select',{attrs:{"options":_vm.companies,"label":"value","clearable":false},on:{"search":_vm.fetchCompanies,"option:selected":_vm.companySelected},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.kpp)+" ")]}}],null,true),model:{value:(_vm.company.details),callback:function ($$v) {_vm.$set(_vm.company, "details", $$v)},expression:"company.details"}},[_c('template',{slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('forms.placeholders.enter-iec-or-ip'))+" ")])],2)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"CompanyAddress","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t('forms.labels.legal-address')))]),_c('b-form-input',{attrs:{"placeholder":_vm.$t('forms.placeholders.legal-address')},model:{value:(_vm.company.address),callback:function ($$v) {_vm.$set(_vm.company, "address", $$v)},expression:"company.address"}})]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}})],1)],1)],1),_c('b-card',{staticClass:"m-0",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"pl-0"},[_c('b-card-title',{staticClass:"text-black",staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.$t('forms.labels.bank-details')))])],1),_c('b-card-body',{staticClass:"p-0"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"BankName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t('forms.labels.bank-name')))]),_c('v-select',{attrs:{"options":_vm.banks,"label":"value","clearable":false},on:{"search":_vm.fetchBanks,"option:selected":_vm.bankSelected},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}}],null,true),model:{value:(_vm.bank.details),callback:function ($$v) {_vm.$set(_vm.bank, "details", $$v)},expression:"bank.details"}},[_c('template',{slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('forms.placeholders.enter-bank-name'))+" ")])],2)]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"BankName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t('forms.labels.bic')))]),_c('v-select',{attrs:{"options":_vm.banks,"label":"value","clearable":false},on:{"search":_vm.fetchBanks,"option:selected":_vm.bankSelected},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.bic)+" ")]}}],null,true),model:{value:(_vm.bank.details),callback:function ($$v) {_vm.$set(_vm.bank, "details", $$v)},expression:"bank.details"}},[_c('template',{slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('forms.placeholders.enter-bic'))+"}} ")])],2)]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"BankName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t('forms.labels.inn')))]),_c('v-select',{attrs:{"options":_vm.banks,"label":"value","clearable":false},on:{"search":_vm.fetchBanks,"option:selected":_vm.bankSelected},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.inn)+" ")]}}],null,true),model:{value:(_vm.bank.details),callback:function ($$v) {_vm.$set(_vm.bank, "details", $$v)},expression:"bank.details"}},[_c('template',{slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('forms.placeholders.enter-inn'))+" ")])],2)]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"BankName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t('forms.labels.iec')))]),_c('v-select',{attrs:{"options":_vm.banks,"label":"value","clearable":false},on:{"search":_vm.fetchBanks,"option:selected":_vm.bankSelected},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.kpp)+" ")]}}],null,true),model:{value:(_vm.bank.details),callback:function ($$v) {_vm.$set(_vm.bank, "details", $$v)},expression:"bank.details"}},[_c('template',{slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('forms.placeholders.enter-iec'))+" ")])],2)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"BankName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t('forms.labels.correspondent-account')))]),_c('b-form-input',{attrs:{"placeholder":_vm.$t('forms.placeholders.correspondent-account')},model:{value:(_vm.bank.korr),callback:function ($$v) {_vm.$set(_vm.bank, "korr", $$v)},expression:"bank.korr"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"BankName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t('forms.labels.settlement-account')))]),_c('b-form-input',{attrs:{"placeholder":_vm.$t('forms.placeholders.settlement-account')},model:{value:(_vm.bank.account),callback:function ($$v) {_vm.$set(_vm.bank, "account", $$v)},expression:"bank.account"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"BankName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t('forms.labels.bank-address')))]),_c('b-form-input',{attrs:{"placeholder":_vm.$t('forms.placeholders.bank-address')},model:{value:(_vm.bank.address),callback:function ($$v) {_vm.$set(_vm.bank, "address", $$v)},expression:"bank.address"}})]}}])})],1)],1)],1)],1)],1),_c('hr',{staticClass:"mt-2 mb-2"}),_c('b-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isModalView),expression:"!isModalView"}]},[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('p',{staticClass:"p-0 m-0"},[_vm._v(_vm._s(_vm.$t('forms.submitting-information'))+" "),_c('b-link',{attrs:{"to":{ name: 'user-documents', params: {type: 'privacy'}}}},[_vm._v(_vm._s(_vm.$t('forms.policy')))]),_vm._v(" "+_vm._s(_vm.$t('forms.and'))+" "),_c('b-link',{attrs:{"to":{ name: 'user-documents', params: {type: 'public-offer'}}}},[_vm._v(_vm._s(_vm.$t('forms.offer')))]),_vm._v(".")],1)]),_c('b-col',{staticClass:"d-flex justify-content-end align-content-end align-items-end",attrs:{"cols":"12","md":"6","lg":"6"}},[_c('showAt',{attrs:{"breakpoint":"mediumAndAbove"}},[_c('b-button',{staticStyle:{"height":"56px","font-size":"16px","border-radius":"16px"},attrs:{"variant":"primary"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t('forms.btns.save-info')))])],1),_c('showAt',{attrs:{"breakpoint":"small"}},[_c('b-button',{staticStyle:{"margin-top":"24px","height":"56px","font-size":"16px","border-radius":"16px"},attrs:{"variant":"primary","block":""},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t('forms.btns.save-info')))])],1)],1)],1),(_vm.isModalView)?_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('b-form-checkbox',{attrs:{"id":"checkbox-2","value":"is_can_save"},model:{value:(_vm.is_need_save),callback:function ($$v) {_vm.is_need_save=$$v},expression:"is_need_save"}},[_c('div',{staticStyle:{"margin-left":"8px","margin-top":"1px"}},[_vm._v(_vm._s(_vm.$t('forms.save-data-from-other-contracts')))])])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }