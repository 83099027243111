<template>
    <b-overlay
      class="create-contract-view"
      :show="is_loading"
      variant='transparent'
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
        
        <b-card class="contract-form">
            <form-calculator ref="info" @dataChanged="formDataChanged" @contractTypeChanged="contractTypeChanged" />
            <hr style="margin-top: 24px; margin-bottom: 24px;">
            <result-view @bonusStateChanged="bonusStateChanged" @createContract="createContract" :diff_percent="diff_percent" :market_price_percent="market_price_percent" :period_profit="period_profit" :total_btc_price="btc_price" :total_ths="total_ths" :current_price="current_price" :period="period" :bonus="bonus" :type="type" />
        </b-card>
        
        <advantage-view :diff_percent="diff_percent" :market_price_percent="market_price_percent" :period_profit="period_profit" :total_btc_price="btc_price" :total_ths="total_ths" :current_price="current_price" :period="period" />
        
        <b-modal
            ref="modal"
            id="contract-details-modal"
            centered
            hide-footer
            @hide="modalWillHide"
          >
              
             <b-card-body v-if="contract">
                <b-card-title class="font-weight-bolder">
                    Контракт <b-link :to="{ name: 'user-contract-details', params: { id: contract.guuid } } ">№{{ contract.guuid }}</b-link> успешно создан
                </b-card-title> 

                <!--<hr class="mt-1 mb-1">-->
             
                <b-list-group flush class="no-hover mb-2">
                    <b-list-group-item class="d-flex justify-content-between pl-0 pr-0" style="padding: 6px 0px;">
                        <span class="text-muted">
                            Мощность
                        </span>
                        <span class="text-black ">
                            {{ contract.ths }} TH/s
                        </span>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between pl-0 pr-0" style="padding: 6px 0px;">
                        <span class="text-muted">
                            Стоимость 1 TH/s
                        </span>
                        <span class="text-black ">
                            {{ current_price.formatMoney(2,' ',' ') }} $
                        </span>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between pl-0 pr-0" style="padding: 6px 0px;">
                        <span class="text-muted">
                            Плата за электроэнергию в месяц
                        </span>
                        <span class="text-black ">
                            {{ energy_payout.formatMoney(2, " ", " ")  }} $
                        </span>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between pl-0 pr-0" style="padding: 6px 0px;">
                        <span class="text-muted">
                            Срок контракта
                        </span>
                        <span class="text-black">
                            {{ period }} {{ getPeriodHolder() }}
                        </span>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between pl-0 pr-0">
                        <span>
                            Сумма к оплате
                        </span>
                        <span class="text-black" style="font-size: 28px; font-weight: 600;">
                            {{ contract.investments.formatMoney(2,' ',' ') }} $
                        </span>
                    </b-list-group-item>
                </b-list-group>
                
                <!--<hr class="mt-1 mb-1">-->
                
                <div v-if="contract.invoice.type === 'btc'">
                    <!-- <p>
                        Для активации контракта переведите <br> <span class='font-weight-bolder'>{{ contract.invoice.btc.decimalBtcMoney() }} ₿ </span> на
                        Bitcoin кошелек: <span class='text-primary cursor-pointer'>{{ contract.invoice.identificator }}</span>.<br><span class='text-muted font-small-3'>После перевода и подтверждения сети, контракт активируется автоматически.</span>
                    </p> -->

                     <div>
                        <p class="text-muted" style="font-size: 13px; line-height: 20px;"> Для активации контракта переведите биткоин (BTC) любым удобным для Вас способом на указаный адрес в течение 15 минут. <br> Если в течении 15 минут платеж не будет произведен, то сумма к оплате изменится в зависимости от курса BTC.</p>
                        <p class="text-muted" style="font-size: 13px; line-height: 20px;"> Оплата будет зачислена после первого подтверждения сети. </p>
                        <p v-if="contract.is_anonymous" class="text-muted" style="font-size: 13px; line-height: 20px;"> Оплата контракта происходит на основании 
                            <b-link to="/documents?show=public-offer&type=1" target='_blank'>публичной оферты.</b-link></p>
                        <p v-else class="text-muted" style="font-size: 13px; line-height: 20px;"> Оплата контракта происходит на основании договора сформированного в личном кабинете. </p>
                    </div>
                    <div class="data-form">
                        <b-form-group>
                            <div class="d-flex justify-content-between">
                                <div style="flex-grow: 1;">
                                    <span class="text-muted" style="font-size: 12px;">Сумма к оплате</span>
                                    <p class="m-0">{{ contract.invoice.btc.decimalBtcMoney() }}</p>
                                </div>
                                <div class="mt-auto mb-auto">
                                    <p class="m-0 text-muted">BTC</p>
                                </div>
                            </div>
                        </b-form-group>
                        
                    </div>
                    <div class="d-flex">

                        <div style="margin-right: 26px;">
                            <b-form-group class="form-group-with-label">
                                <div ref="container" class="d-flex justify-content-between"> 
                                    <div class="wordwrap">
                                        <span class="text-muted" style="font-size: 12px;">Bitcoin адрес для оплаты</span>
                                        <p style="font-size: 16px; font-weight: 500; margin: 0; word-break: break-all;">{{ contract.invoice.identificator }}</p>
                                    </div>
                                    <div 
                                        v-ripple 
                                        @click="copyBitcoinAddress"
                                        style="margin: auto 12px; margin-right: 0;">
                                        <feather-icon 
                                            icon="CopyIcon" 
                                            size="20" 
                                            class="text-muted cursor-pointer" 
                                        />
                                    </div>
                                </div>
                            </b-form-group>
                        </div>

                        <div>
                            <qrcode :value="`${contract.invoice.identificator}`" :options="{ width: 80, margin: 0 }"></qrcode>
                        </div>

                    </div>
                    
                    <b-row style="margin-top: 12px;">
                        <b-col>
                        <b-button :to="{ name: 'user-wallet', query: { act: 'output', to: contract.invoice.identificator, amount: contract.invoice.btc.decimalBtcMoney() } }" replace size="lg" pill variant="primary" block style='padding-left:0; padding-right: 0;'>Оплатить из кошелька</b-button>
                        </b-col>
                        <b-col>
                        <b-button @click="$bvModal.hide('contract-details-modal')" size="lg" pill variant="gray" block style='padding-left:0; padding-right: 0;'>Оплатить потом</b-button>
                        </b-col>
                    </b-row>

                </div>
                
                <div v-else >
                    <b-row>
                        <b-col>
                            <b-button :href="payment_make_url" target="_blank" size="lg" pill variant="primary" block style='padding-left:0; padding-right: 0;'>Перейти к оплате</b-button>
                        </b-col>
                        <b-col v-if="!contract.is_anonymous">
                            <b-button :href="doc_pdf" target="_blank" size="lg" pill variant="outline-secondary" block  style='padding-left:0; padding-right: 0;'>Скачать договор</b-button>
                        </b-col>
                    </b-row>
                </div>
                
                <!-- <b-row class='mt-1'>
                    <b-col cols="12" class="d-flex">
                        <b-link @click="$bvModal.hide('contract-details-modal')" class="text-center ml-auto mr-auto">Оплатить потом</b-link>
                    </b-col>
                </b-row> -->
                
             </b-card-body>
             
        </b-modal>
        
    </b-overlay>
</template>

<script>

    import FormCalculator from "./form-calculator"
    import ResultView from "./widgets/result.tablet"
    import AdvantageView from "./widgets/advantage"
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


    export default {
        props: {
            bonus: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                
                is_loading: false,
                
                contract: null,
                
                btc_price: 0,
                period_profit: 0,
                total_ths: 0,
                current_price: 0,
                period: 12,
                // diff_percent: 2.4,
                // market_price_percent: 5,
                diff_percent: 1.77,
                market_price_percent: 3,
                investments: 0,

                is_bonus_active: false,
                bonus_percent: 0,

                type: 2,
                
            }
        },
        methods: {
            
            copyBitcoinAddress( args ) {
                
                this.$copyText(this.contract.invoice.identificator, this.$refs.container );
                
                this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$t('toasts.titles.copy'),
                      text: this.$t('toasts.text.btc-address'),
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                    }
                });  
            },

            contractTypeChanged(type) {
                this.type = type.type
            },

            formDataChanged( args ) {
                
                this.period = args.period;
                this.period_profit = args.period_profit;
                this.total_ths = args.total_ths;
                this.current_price = args.current_price / args.period;
                this.btc_price = args.btc;
                this.investments = args.investments;
                
            },
        
            bonusStateChanged( args ) {
                this.is_bonus_active = args.is_bonus_active;
                this.bonus_percent = args.bonus_percent;
            },
            
            modalWillHide() {
                this.$router.replace({ 
                    name: 'user-contract-details', 
                    params: { 
                        id: this.contract.guuid 
                    } 
                });
            },
            
            getPeriodHolder() {
                var placeholder = this.declOfNum( this.period, ['месяц', 'месяца', 'месяцев']);
                return `${placeholder}`;
            },
            
            declOfNum(number, titles) {  
                let cases = [2, 0, 1, 1, 1, 2];  
                if(number === 0) {
                    return titles[2];
                }
                return titles[ (number % 100 > 4 && number % 100 < 20 )? 2 : cases[(number%10<5)?number%10:5] ]; 
            },
            
            
            createContract( args ) {
                
                this.$refs.info.getFormData().then( params => {
                   
                    if( !params ) return;

                    this.is_loading = true;

                    if( this.$route.query['with-offer'] ) {
                        params['offer'] = this.$route.query['with-offer'];
                    }

                    if (localStorage.getItem("utmTags")) {
                        params['utm_tags'] = JSON.parse(localStorage.getItem("utmTags"))
                    }

                    params['bonus'] = this.is_bonus_active ? this.bonus_percent : null

                    this.$request.post("contracts.create", params ).then( result => {

                        this.$request.get("contracts.get", {
                            id: result.guuid
                        }).then( result => {
                            
                            this.contract = result;  
                            this.period = result.period;

                             this.is_loading = false;
                             
                            //  localStorage.removeItem('utmTags');
                             
                             this.$refs.modal.show();
                             
                        }).catch(err => {
                            this.is_loading = false;
                        });

                    }).catch(err => {
                        this.is_loading = false;
                    });
                   
                });
                
            },
            
        },
        computed: {

            energy_payout_btc() {
                // электроэнергия за весь период (если выбрано отображение за период, в summary убрать умножение на period)
                // return ( this.total_ths * ( this.$user.contracts.values.E * 24 * 30 ) * this.period ) / this.$blockchain.tickers.values.USD.last; 

                // электроэнергия за месяц
                return ( this.contract.ths * ( this.$user.contracts.values.E * 24 * 30 ) ) / this.$blockchain.tickers.values.USD.last; 
            },
            
            energy_payout() {
                return this.energy_payout_btc * this.$blockchain.tickers.values.USD.last;
            },

            doc_pdf() {
                return `${process.env.VUE_APP_BASE_URL}doc/${this.contract.doc}`
            },

            payment_make_url() {
                return `${process.env.VUE_APP_BASE_URL}payment-make/${this.contract.invoice.identificator}`
            }

        },
        components: {
            FormCalculator,
            ResultView,
            AdvantageView
        },
        watch: {

        },
        mounted() {

        }

    }

</script>