<template>
    <b-overlay
      class="create-contract-view"
      :show="is_loading"
      variant='transparent'
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
        
        <b-row>
            
            <b-col cols="8">
                <b-card nob-body>
                    <form-calculator ref="info" @dataChanged="formDataChanged" @contractTypeChanged="contractTypeChanged" />
                    <div>
                        <hr class="mt-2 mb-2">
                        <b-row>
                            <b-col md="8" cols="12">
                                <p v-if="type == 2" class="text-muted" style="font-size: 12px; line-height: 20px;">
                                    <!-- Расчет условий доходности является предварительным,<br>не является публичной офертой.s -->
                                    <i18n path="contracts.calculation-conditions" tap="span"> 
                                        <br>
                                    </i18n>
                                    <br>
                                    <!-- Нажимая на "Создать контракт", -->
                                    {{$t('contracts.clicking-create')}}
                                    <br> 
                                    <!-- Вы соглашаетесь с условиями  -->
                                    {{$t('contracts.you-agree')}}
                                    <b-link to="/documents?show=public-offer&type=1" target="_blank" >{{$t('contracts.public-offer')}}</b-link>.
                                </p>
                                <p v-else class="text-muted" style="font-size: 12px; line-height: 20px;">
                                   {{$t('contracts.you-agree')}}, <br>
                                    <i18n path="contracts.you-agree-doc" tap="span"> 
                                        <br>
                                    </i18n>
                                </p>
                            </b-col>

                            <b-col md="4" cols="12" class="d-flex p-0 m-0">
                                <b-button @click="createContract" size="lg" block variant="primary" class="ml-auto p-0" style="font-size: 16px; height: 56px; border-radius: 16px; font-weight: 500;">
                                    {{$t('contracts.btns.create')}}
                                </b-button>
                            </b-col>
                        </b-row>
                    </div>
                </b-card>
            </b-col>
            
            <b-col cols="4">
                <result-view @bonusStateChanged="bonusStateChanged" @createContract="createContract" :diff_percent="diff_percent" :market_price_percent="market_price_percent" :period_profit="period_profit" :total_btc_price="btc_price" :total_ths="total_ths" :current_price="current_price" :period="period" :bonus="bonus" />
                <advantage-view :diff_percent="diff_percent" :market_price_percent="market_price_percent" :period_profit="period_profit" :total_btc_price="btc_price" :total_ths="total_ths" :current_price="current_price" :period="period" />
            </b-col>
            
        </b-row>
    
        
    </b-overlay>
</template>

<script>

    import FormCalculator from "./form-calculator"
    import ResultView from "./widgets/result.desktop"
    import AdvantageView from "./widgets/advantage"

    export default {
        props: {
            bonus: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                is_loading: false,
                
                contract: null,
                
                btc_price: 0,
                period_profit: 0,
                total_ths: 0,
                current_price: 0,
                period: 12,
                diff_percent: 1.77,
                market_price_percent: 3,
                investments: 0,

                summ: 0,

                is_bonus_active: false,
                bonus_percent: 0,

                type: 2,
                
            }
        },
        methods: {
            contractTypeChanged(type) {
                this.type = type.type
            },

            formDataChanged( args ) {
                this.period = args.period;
                this.period_profit = args.period_profit;
                this.total_ths = args.total_ths;
                this.current_price = args.current_price / args.period;
                this.btc_price = args.btc;
                this.investments = args.investments;
            },

            bonusStateChanged( args ) {
                this.is_bonus_active = args.is_bonus_active;
                this.bonus_percent = args.bonus_percent;
            },
            
            modalWillHide() {
                this.$router.replace({ 
                    name: 'user-contract-details', 
                    params: { 
                        id: this.contract.guuid 
                    } 
                });
            },
            
            getPeriodHolder() {
                var placeholder = this.declOfNum( this.period, ['месяц', 'месяца', 'месяцев']);
                return `${placeholder}`;
            },
            
            declOfNum(number, titles) {  
                let cases = [2, 0, 1, 1, 1, 2];  
                if(number === 0) {
                    return titles[2];
                }
                return titles[ (number % 100 > 4 && number % 100 < 20 )? 2 : cases[(number%10<5)?number%10:5] ]; 
            },
            
            
            createContract( args ) {

                this.$refs.info.getFormData().then( params => {
                   
                    if( !params ) return;

                    this.is_loading = true;

                    params['bonus'] = this.is_bonus_active ? this.bonus_percent : null

                    if( this.$route.query['with-offer'] ) {
                        params['offer'] = this.$route.query['with-offer'];
                        params['bonus'] = null
                    }

                    if (localStorage.getItem("utmTags")) {
                        params['utm_tags'] = JSON.parse(localStorage.getItem("utmTags"))
                    }


                    this.$request.post("contracts.create", params ).then( result => {

                        this.$emit( "created", result );
                        // localStorage.removeItem('utmTags');
                        this.is_loading = false;

                    }).catch(err => {
                        this.is_loading = false;
                    });
                   
                });
                
            },
            
        },
        components: {
            FormCalculator,
            AdvantageView,
            ResultView,
        },
        watch: {

        },
        mounted() {

        }

    }

</script>