<template>

    <validation-observer ref="entity">
        <b-form @submit.prevent="save" class="data-form"> 
            
            <b-card no-body class="m-0">
                <b-card-header class="pl-0">
                    <b-card-title class="text-black" style="font-size: 14px;">{{$t('forms.company-info')}}</b-card-title>
                </b-card-header>
                
                <b-card-body class="p-0">
                    
                    <b-row>

                        <b-col md="12">
                            <b-form-group>
                                
                                <validation-provider
                                    #default="{ errors }"
                                    name="CompanyName"
                                    rules="required"
                                >
                                    <span class="text-muted">{{$t('forms.name-entity')}}</span>
                                    <v-select
                                      :options="companies"
                                      v-model="company.details"
                                      label="value"
                                      @search="fetchCompanies"
                                      @option:selected="companySelected"
                                      :clearable="false"
                                    >
                                        <template slot="no-options">
                                           {{$t('forms.labels.enter-name-entity')}}
                                        </template>
                                        
                                        <template #selected-option="{ data }">
                                            {{ data.name.full_with_opf }}
                                        </template>
                                        
                                    </v-select>
                                    
                                    <small class="text-danger font-small-1">{{ errors[0] }}</small>
                                    
                                </validation-provider>
                                
                            </b-form-group>
                        </b-col>     
                        
                        <b-col md="12">
                            
                            <b-form-group>
                                
                                <validation-provider
                                    #default="{ errors }"
                                    name="CompanyName"
                                    rules="required"
                                >
                                    <span class="text-muted">{{$t('forms.labels.gen-director')}}</span>
                                    
                                    <b-form-input
                                      :placeholder="$t('forms.placeholders.gen-director')"
                                      v-model="company.director"
                                    />
                                    
                                </validation-provider>
                                
                            </b-form-group>
                            
                        </b-col>
                        
                        <b-col md="4">
                            <b-form-group>
                                <validation-provider
                                    #default="{ errors }"
                                    name="CompanyInn"
                                    rules="required"
                                >
                                    <span class="text-muted">{{$t('forms.labels.inn')}}</span>
                                    <v-select
                                      :options="companies"
                                      v-model="company.details"
                                      label="value"
                                      @search="fetchCompanies"
                                      @option:selected="companySelected"
                                      :clearable="false"
                                    >
                                        <template slot="no-options">
                                            {{$t('forms.labels.enter-inn-or-ip')}}
                                        </template>
                                        
                                        <template #selected-option="{ data }">
                                            {{ data.inn }}
                                        </template>
                                        
                                    </v-select>
                                    
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        
                        <b-col md="4">
                            <b-form-group>
                                 <validation-provider
                                    #default="{ errors }"
                                    name="CompanyOgrn"
                                    rules="required"
                                >
                                    <span class="text-muted">{{$t('forms.labels.psrn')}}</span>
                                    <v-select
                                      :options="companies"
                                      v-model="company.details"
                                      label="value"
                                      @search="fetchCompanies"
                                      @option:selected="companySelected"
                                      :clearable="false"
                                    >
                                        <template slot="no-options">
                                            {{$t('forms.placeholders.enter-psrn-or-ip')}}
                                        </template>
                                        
                                        <template #selected-option="{ data }">
                                            {{ data.ogrn }}
                                        </template>
                                        
                                    </v-select>
                                    
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        
                        <b-col md="4">
                            <b-form-group>
                                 <validation-provider
                                    #default="{ errors }"
                                    name="CompanyKpp"
                                    rules="required"
                                >
                                    <span class="text-muted">{{$t('forms.labels.iec')}}</span>
                                    <v-select
                                      :options="companies"
                                      v-model="company.details"
                                      label="value"
                                      @search="fetchCompanies"
                                      @option:selected="companySelected"
                                      :clearable="false"
                                    >
                                        <template slot="no-options">
                                            {{$t('forms.placeholders.enter-iec-or-ip')}}
                                        </template>
                                        
                                        <template #selected-option="{ data }">
                                            {{ data.kpp }}
                                        </template>
                                        
                                    </v-select>
                                    
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                    </b-row>

                    <b-row>

                        <b-col md="12">
                            <b-form-group>
                                <validation-provider
                                    #default="{ errors }"
                                    name="CompanyAddress"
                                    rules="required"
                                >
                                    <span class="text-muted">{{$t('forms.labels.legal-address')}}</span>
                                    <b-form-input
                                      :placeholder="$t('forms.placeholders.legal-address')"
                                      v-model="company.address"
                                    />

                                </validation-provider>
                            </b-form-group>
                        </b-col>

                    </b-row>

                    <b-row>

                        <b-col md="12">

                        </b-col>                

                    </b-row>
                    
                </b-card-body>
                
            </b-card>
            
            <b-card no-body class="m-0">
                
                <b-card-header class="pl-0">
                    <b-card-title class="text-black" style="font-size: 14px;">{{$t('forms.labels.bank-details')}}</b-card-title>
                </b-card-header>
                
                <b-card-body class="p-0">
                    <b-row>
                        <b-col md="12">
                            <b-form-group>
                                
                                <validation-provider
                                    #default="{ errors }"
                                    name="BankName"
                                    rules="required"
                                >
                                    <span class="text-muted">{{$t('forms.labels.bank-name')}}</span>
                                    <v-select
                                      :options="banks"
                                      v-model="bank.details"
                                      label="value"
                                      @search="fetchBanks"
                                      @option:selected="bankSelected"
                                      :clearable="false"
                                    >
                                        <template slot="no-options">
                                            {{$t('forms.placeholders.enter-bank-name')}}
                                        </template>
                                        
                                        <template #selected-option="{ value }">
                                            {{ value }}
                                        </template>
                                        
                                    </v-select>
                                    
                                </validation-provider>
                                
                            </b-form-group>
                        </b-col>
                        
                        
                        <b-col md="4">
                            <b-form-group>
                                
                                <validation-provider
                                    #default="{ errors }"
                                    name="BankName"
                                    rules="required"
                                >
                                    <span class="text-muted">{{$t('forms.labels.bic')}}</span>
                                    <v-select
                                      :options="banks"
                                      v-model="bank.details"
                                      label="value"
                                      @search="fetchBanks"
                                      @option:selected="bankSelected"
                                      :clearable="false"
                                    >
                                        <template slot="no-options">
                                            {{$t('forms.placeholders.enter-bic')}}}}
                                        </template>
                                        
                                        <template #selected-option="{ data }">
                                            {{ data.bic }}
                                        </template>
                                        
                                    </v-select>
                                    
                                </validation-provider>
                                
                            </b-form-group>
                        </b-col>
                        
                        
                        <b-col md="4">
                            <b-form-group>
                                
                                <validation-provider
                                    #default="{ errors }"
                                    name="BankName"
                                    rules="required"
                                >
                                    <span class="text-muted">{{$t('forms.labels.inn')}}</span>
                                    <v-select
                                      :options="banks"
                                      v-model="bank.details"
                                      label="value"
                                      @search="fetchBanks"
                                      @option:selected="bankSelected"
                                      :clearable="false"
                                    >
                                        <template slot="no-options">
                                            {{$t('forms.placeholders.enter-inn')}}
                                        </template>
                                        
                                        <template #selected-option="{ data }">
                                            {{ data.inn }}
                                        </template>
                                        
                                    </v-select>
                                    
                                </validation-provider>
                                
                            </b-form-group>
                        </b-col>
                        
                        <b-col md="4">
                            <b-form-group>
                                
                                <validation-provider
                                    #default="{ errors }"
                                    name="BankName"
                                    rules="required"
                                >
                                    <span class="text-muted">{{$t('forms.labels.iec')}}</span>
                                    <v-select
                                      :options="banks"
                                      v-model="bank.details"
                                      label="value"
                                      @search="fetchBanks"
                                      @option:selected="bankSelected"
                                      :clearable="false"
                                    >
                                        <template slot="no-options">
                                            {{$t('forms.placeholders.enter-iec')}}
                                        </template>
                                        
                                        <template #selected-option="{ data }">
                                            {{ data.kpp }}
                                        </template>
                                        
                                    </v-select>
                                    
                                </validation-provider>
                                
                            </b-form-group>
                        </b-col>
                        
                        <b-col md="6">
                            <b-form-group>
                                
                                <validation-provider
                                    #default="{ errors }"
                                    name="BankName"
                                    rules="required"
                                >
                                    <span class="text-muted">{{$t('forms.labels.correspondent-account')}}</span>
                                    <b-form-input
                                      :placeholder="$t('forms.placeholders.correspondent-account')"
                                      v-model="bank.korr"
                                    />
                                    
                                </validation-provider>
                                
                            </b-form-group>
                        </b-col>
                        
                        <b-col md="6">
                            <b-form-group>
                                
                                <validation-provider
                                    #default="{ errors }"
                                    name="BankName"
                                    rules="required"
                                >
                                    <span class="text-muted">{{$t('forms.labels.settlement-account')}}</span>
                                    <b-form-input
                                      :placeholder="$t('forms.placeholders.settlement-account')"
                                      v-model="bank.account"
                                    />
                                    
                                </validation-provider>
                                
                            </b-form-group>
                        </b-col>
                        
                        <b-col md="12">
                            <b-form-group>
                                
                                <validation-provider
                                    #default="{ errors }"
                                    name="BankName"
                                    rules="required"
                                >
                                    <span class="text-muted">{{$t('forms.labels.bank-address')}}</span>
                                    <b-form-input
                                      :placeholder="$t('forms.placeholders.bank-address')"
                                      v-model="bank.address"
                                    />
                                    
                                </validation-provider>
                                
                            </b-form-group>
                        </b-col>
                        
                    </b-row>
                </b-card-body>
                
            </b-card>
            
            <hr class="mt-2 mb-2">
            
            <b-row v-show="!isModalView">
                <b-col cols="12" md="6" lg="6">
                    <p class="p-0 m-0">{{$t('forms.submitting-information')}} <b-link :to="{ name: 'user-documents', params: {type: 'privacy'}}">{{$t('forms.policy')}}</b-link> {{$t('forms.and')}} <b-link :to="{ name: 'user-documents', params: {type: 'public-offer'}}">{{$t('forms.offer')}}</b-link>.</p>
                </b-col>
                <b-col cols="12" md="6" lg="6" class="d-flex justify-content-end align-content-end align-items-end">
                    <showAt breakpoint="mediumAndAbove">
                        <b-button @click="save" variant="primary" style="height: 56px; font-size: 16px; border-radius: 16px;" >{{$t('forms.btns.save-info')}}</b-button>
                    </showAt>
                    <showAt breakpoint="small">
                        <b-button @click="save" variant="primary" block style="margin-top: 24px; height: 56px; font-size: 16px; border-radius: 16px;" >{{$t('forms.btns.save-info')}}</b-button>
                    </showAt>
                </b-col>
            </b-row>

            <b-row v-if="isModalView" class="mb-2">

                <b-col>
                    <b-form-checkbox
                        id="checkbox-2"
                        v-model="is_need_save"
                        value="is_can_save"
                      >
                        <div style="margin-left: 8px; margin-top: 1px;">{{$t('forms.save-data-from-other-contracts')}}</div>
                    </b-form-checkbox>
                </b-col>

            </b-row>
            
        </b-form>
    </validation-observer>
    
</template>

<script>

    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import Cache from "@/modules/cache/"
    import User from "@/modules/user/"
    import vSelect from 'vue-select'
    import Cleave from 'vue-cleave-component'

    import {
        required
    } from '@validations'

    export default {

        data() {
            return {
                
                required, 
                
                is_need_save: "",
                
                company: {
                    
                    details: User.self.entity ? JSON.parse(User.self.entity.company_raw) : null,
                    name: User.self.entity ? User.self.entity.name : "",
                    director: User.self.entity ? User.self.entity.director : "",
                    inn: User.self.entity ? User.self.entity.inn : "",
                    ogrn: User.self.entity ? User.self.entity.ogrn : "",
                    kpp: User.self.entity ? User.self.entity.kpp : "",
                    address: User.self.entity ? User.self.entity.address : "",
                    
                },
                
                bank: {
                    details: User.self.entity ? JSON.parse(User.self.entity.bank_raw) : null,
                    name: User.self.entity ? User.self.entity.bank_name : "",
                    bik: User.self.entity ? User.self.entity.bank_bik : "",
                    inn: User.self.entity ? User.self.entity.bank_inn : "",
                    kpp: User.self.entity ? User.self.entity.bank_kpp : "",
                    address: User.self.entity ? User.self.entity.bank_address : "",
                    korr: User.self.entity ? User.self.entity.bank_corr : "",
                    account: User.self.entity ? User.self.entity.bank_account : "",
                },
                
                companies: [],
                banks: [],
                
            }
        },
        
        props: {
            isModalView: {
                type: Boolean,
                default: false
            },
            doneButtonText: {
                type: String,
                default: "Готово"
            }
        },
        
        methods: {
            
            companySelected( args ) {
                
                if( this.company.details.data.type === 'INDIVIDUAL' ) {
                    this.company.name = this.company.details.data.name.short_with_opf;
                    this.company.director = this.company.details.data.name.full;
                } else {
                    this.company.name = this.company.details.data.name.short_with_opf;
                    this.company.director = this.company.details.data.management.name;
                }
                
                this.company.inn = this.company.details.data.inn;
                this.company.ogrn = this.company.details.data.ogrn;
                this.company.kpp = this.company.details.data.kpp;
                this.company.address = this.company.details.data.address.unrestricted_value;
                
            },
            
            bankSelected( args ) {
                
                this.bank.name =  this.bank.details.value;
                this.bank.bik = this.bank.details.data.bic;
                this.bank.inn = this.bank.details.data.inn;
                this.bank.kpp = this.bank.details.data.kpp;
                this.bank.address = this.bank.details.data.address.unrestricted_value;
                this.bank.korr = this.bank.details.data.correspondent_account;
                
            },
            
            fetchBanks( query, loading ) {
                
                var url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/bank";
                var token = "0d5cc6634c68e2420ce9e3482d67b9b66ea5115d";

                var options = {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Token " + token
                    },
                    body: JSON.stringify({query: query})
                }

                var self = this;

                fetch(url, options)
                .then(response => {
                   response.json().then( result => {
                       self.banks = result.suggestions;
                   })
                })
                .catch( error => console.log("error", error));
                
            },

            fetchCompanies( query, loading ) {
                
                var url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party";
                var token = "0d5cc6634c68e2420ce9e3482d67b9b66ea5115d";

                var options = {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Token " + token
                    },
                    body: JSON.stringify({query: query})
                }

                var self = this;

                fetch(url, options)
                .then(response => {
                   response.json().then( result => {
                       self.companies = result.suggestions;
                       console.log( self.companies );
                   })
                })
                .catch( error => console.log("error", error));
                
            },

            validate() {
                 return this.$refs.entity.validate();
             },

            submit() {
                 
                this.$refs.entity.validate().then(success => {
                     
                     if( success ) {
                         
                        if( this.is_need_save === "is_can_save" ) {
                            
                            let params = {
                                company: this.company,
                                bank: this.bank
                            };

                            this.$request.post("user.personal.update", params).then( result => {

                            }).catch(err => {

                            });
                            
                        } 
                         
                        this.$emit("data-changed", {
                            company: this.company,
                            bank: this.bank
                        });
                        
                     }
                });
                 
             },
            
             save() {
                 
                 if( this.isModalView ) {
                     this.submit();
                     return;
                 }
                 
                 this.$refs.entity.validate().then(success => {
                    
                      if( !success ) return;
                      
                      let params = {
                          company: this.company,
                          bank: this.bank
                      };
                      
                      this.$request.post("user.entity.update", params).then( result => {
                          
                      }).catch(err => {
                          
                      });
                        
                 });
                 
             }

        },
        
        components: {
            ValidationProvider,
            ValidationObserver,
            vSelect,
            Cleave
        },
        
        watch: {

        },
        mounted() {

        }

    }

</script>


<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
 