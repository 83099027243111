<template>
    <b-card no-body style="margin-top: 16px;">
                    
            <b-card-title style="font-size: 14px; margin: 16px 24px 8px 24px;">
                {{$t('calculator.mining-advantages')}}
                <span class="text-muted">
                    <feather-icon 
                        v-b-popover.hover.top="$t('popovers.comparison')"
                        icon="AlertCircleIcon" 
                        class="cursor-pointer info-hover" 
                        style="margin-left: 6px;"
                    />
                </span>
            </b-card-title>

            <b-card-body style="padding: 0 24px 24px 24px;">

                <span>{{$t('calculator.buy-contract')}}</span>

                <div class="d-flex">
                    <b-progress-bar
                        :min="0"
                        :value="( period / 24 ) * 100 "
                        :max="100"
                        label=" "
                        class="mt-auto mb-auto mr-1"
                        style="height: 8px; border-radius: 8px; background-color: #3ED085; max-width: 75%;"
                     />
                    <div  class="text-muted" style="margin-right: 4px;">{{ summary_in_btc.formatMoney(5,' ', ' ') }} ₿ </div>

                </div>



                <p class="text-primary font-medium-5" style="margin-bottom: 4px;">vs</p>

                <span>{{$t('calculator.buy-btc')}}</span>

                <div class="d-flex ">
                    <b-progress-bar
                        :min="0"
                        :value="( (period / 3) / 24 ) * 100 "
                        :max="100"
                        label=" "
                        class="mt-auto mb-auto mr-1"
                        style="height: 8px; border-radius: 8px; background-color: #9CA2B1; max-width: 75%;"
                     />
                    <div class="text-muted" style="margin-right: 4px;">{{ total_btc_price.formatMoney(5,' ', ' ') }} ₿ </div>
                </div>

            </b-card-body>

        </b-card>
</template>

<script>

    import User from "@/modules/user/"

    export default {

        data() {
            return {
                User 
            }
        },
        methods: {
            
        },
        
        props: {
            total_ths: {
                type: [ String, Number ],
                default: 0
            },
            current_price: {
                type: [ String, Number ],
                default: 0
            },
            total_btc_price: {
                type: [ String, Number ],
                default: 0
            },
            
            calcType: {
                type: [ String, Number ],
                default: 0 // history
            },
            
            diff_history: {
                type: [ String, Number ],
                default: 10,
            },
            
            btc_percent: {
                type: [ String, Number ],
                default: 10
            },
            
            diff_percent: {
                type: [ String, Number ],
                default: 0
            },
            
            period: {
                type: [ String, Number ],
                default: 12
            },
            
            market_price_percent: {
                type: [ String, Number ],
                default: 10
            }
        },
        
        computed: {

            fee() {
                return (this.period_profit * 0.025) * this.market_price;
            },

            fee_btc() {
                return this.period_profit * 0.025;
            },
            
            service() {
                return (this.period_profit * 0.05) * this.market_price;
            },

            service_btc() {
                return this.period_profit * 0.05;
            },
            
            difficult() {
                
                let temp = parseInt(this.$user.contracts.values.D) * (this.diff_percent / 100);
                let growing = temp * this.period;
                let diff =parseInt(this.$user.contracts.values.D) + growing;
                
                if( diff <= 1000000000 ) {
                    diff = 1000000000;
                }
                                
                return parseInt(diff);
                
            },
            
            market_price() {
                // формула вычета сложно процента 
                if (User.currency === 'USD') {
                    return this.$blockchain.tickers.values[User.currency].last * (Math.pow((1 + this.market_price_percent / 100), this.period))
                } else if  (User.currency === 'BTC') {
                    return 1;
                } else {
                    return this.$blockchain.tickers.values[User.currency].last;
                }  
            },

            market_price_btc() {
                let temp = this.$blockchain.tickers.values['BTC'].last * ( this.market_price_percent / 100 );
                let growing = temp * this.period;
                
                return this.$blockchain.tickers.values['BTC'].last + growing;
            },
            
            period_profit() {
                // через средний рост сложности майнинга
                // return (86400 * 30 * this.period * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.total_ths )) / ( ((this.difficult + parseInt(this.$user.contracts.values.D)) / 2 ) * Math.pow(2, 32) );

                let profit = 0;
                let temp = parseInt(this.$user.contracts.values.D) * (this.diff_percent / 100);
                let diff = parseInt(this.$user.contracts.values.D);

                for (let i = 0; i < this.period; i++) {
                   profit += (86400 * 30 * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.total_ths )) / ( diff * Math.pow(2, 32) );

                   diff += temp;
                }

                return profit;
            },

            period_profit_raw() {
                 return (((84600 * 30 * this.period * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.total_ths )) / ( this.difficult * Math.pow(2, 32) )) ) - ( this.total_ths * 0.000042 * this.period ) ;
            },    
            
            energy_payout_btc() {
                // электроэнергия за весь период (если выбрано отображение за период, в summary убрать умножение на period)
                // return ( this.total_ths * ( this.$user.contracts.values.E * 24 * 30 ) * this.period ) / this.$blockchain.tickers.values.USD.last; 

                // электроэнергия за месяц
                return ( this.total_ths * ( this.$user.contracts.values.E * 24 * 30 ) ) / this.$blockchain.tickers.values.USD.last; 
            },
            
            energy_payout() {
                return this.energy_payout_btc * this.$blockchain.tickers.values[User.currency].last;
            },
                
            summary() {
                //v2
                return (this.period_profit * this.market_price) - this.energy_payout;

                //v1
                // return (this.period_profit * this.market_price) - this.energy_payout * this.period;
            },

            summary_in_btc() {
               return this.period_profit - this.energy_payout_btc - this.fee_btc - this.service_btc;  
            },
            
            investments() {
                return this.total_btc_price * this.$blockchain.tickers.values[User.currency].last; 
            },
            
            percent() {
                // return (( this.summary  / this.investments) * 100).toFixed(2);
                return (( (this.summary - this.fee - this.service - this.investments)  / this.investments) * 100).toFixed(2);
            },

            month_energy_payout_usd() {
                // за месяц
                return this.total_ths * ( this.$user.contracts.values.E * 24 * 30 );
            },

            energy_payout() {
                let energy_btc = 0;

                let month_energy_usd = this.month_energy_payout_usd
                let usd_rate = this.$blockchain.tickers.values.USD.last; 

                for (let i = 0; i < this.period; i++) {
                    energy_btc += month_energy_usd / usd_rate;

                    usd_rate = usd_rate * (1 + this.market_price_percent / 100);
                }

                if (User.currency === "BTC") {
                    return energy_btc;
                } else if (User.currency === "USD") {
                    return month_energy_usd * this.period;
                } else {
                    return energy_btc * this.$blockchain.tickers.values[User.currency].last;
                }
            },

            energy_payout_btc() {
                let energy_btc = 0;

                let month_energy_usd = this.month_energy_payout_usd
                let usd_rate = this.$blockchain.tickers.values.USD.last; 

                for (let i = 0; i < this.period; i++) {
                    energy_btc += month_energy_usd / usd_rate;

                    usd_rate = usd_rate * (1 + this.market_price_percent / 100);
                }

                return energy_btc;
            }
            
        },
        
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>