<template>
    <b-card no-body >
       <b-card-body style="padding: 24px;">
            <p style="margin-bottom: 4px;">{{$t('calculator.expected-income-in')}}<b-link @click="changeCurrency"> {{ User.currency }}</b-link> </p>
            <div v-if="!is_bonus_active" class="d-flex">
                <p style="font-size: 28px; font-weight: 600;">{{ (summary - fee - service).formatMoney(User.currency === "BTC" ? 4 : 0,' ', ' ') }} <span class="font-weight-normal">{{ User.getSymbol() }}</span> </p>
                <span  v-if="percent > 0" style="color: #3ED085; padding-left: 5px; ">
                    +{{ percent }}%
                </span>
                <span  v-else style="color: #FF6F71; padding-left: 5px; ">
                    {{ percent }}%
                </span>
                <feather-icon 
                    v-b-popover.hover.top="$t('popovers.income-with-calculator')"
                    icon="AlertCircleIcon" 
                    class="cursor-pointer info-hover text-muted"
                    style="margin: 3px" 
                />
            </div>
            <div v-else class="d-flex">
                <p style="font-size: 28px; font-weight: 600;">{{ summary_with_bonus.formatMoney(User.currency === "BTC" ? 4 : 0,' ', ' ') }} <span class="font-weight-normal">{{ User.getSymbol() }}</span> </p>
                <span  v-if="percent_with_bonus > 0" style="color: #3ED085; padding-left: 5px; ">
                    +{{ percent_with_bonus }}%
                </span>
                <span  v-else style="color: #FF6F71; padding-left: 5px; ">
                    {{ percent_with_bonus }}%
                </span>
                <feather-icon 
                    v-b-popover.hover.top="$t('popovers.income-with-calculator')"
                    icon="AlertCircleIcon" 
                    class="cursor-pointer info-hover text-muted"
                    style="margin: 3px" 
                />
            </div>
       
            <p class="text-muted" style="margin-bottom: 6px;">{{$t('calculator.contract-value')}}</p>
                
            <div class="d-flex">

                <div class="energy-icon mt-auto mb-auto" style="margin-right: 6px;"></div>

                <p class="mb-0">
                    <span v-if="!is_bonus_active" style="font-size: 24px; font-weight: 500;">{{ total_ths.formatMoney(2,' ', ' ') }}</span>
                    <span v-else style="font-size: 24px; font-weight: 500;">{{ total_ths_with_bonus.formatMoney(2,' ', ' ') }}</span>
                    <span style="font-size: 18px;"> TH/s</span>
                    <feather-icon 
                        v-b-popover.hover.top="$t('popovers.ths')"
                        icon="AlertCircleIcon" 
                        class="cursor-pointer info-hover text-muted"
                        style="margin: 0px 0px 3px 3px;" 
                    />
                    <span v-if="is_bonus_active" style="margin: 0 3px; color: #3ED085"> +{{bonus_percent}}% {{$t('calculator.bonus')}}</span>
                </p>
            </div>

            <hr style="margin-top: 16px; margin-bottom: 16px;">


            <div style="font-size: 14px; margin-bottom: 24px;">
                <div class="d-flex justify-content-between" style="margin: 4px 0;">
                    <span class="text-muted font-weight-light">
                        {{$t('calculator.cost-1ths-month')}}
                        <feather-icon 
                            v-b-popover.hover.top="$t('popovers.ths')"
                            icon="AlertCircleIcon" 
                            class="cursor-pointer info-hover text-muted" 
                        />
                    </span>
                    <span>
                       {{ current_price.formatMoney(2,' ', ' ') }} $
                    </span>
                </div>

                <div class="d-flex justify-content-between" style="margin: 4px 0;">
                    <span class="text-muted font-weight-light">
                        {{$t('calculator.monthly-electricity-fee')}}
                        <feather-icon 
                            v-b-popover.hover.top="$t('popovers.electricity-fee-a')+ ' ' +parseFloat($user.contracts.values.E)+ ' ' +$t('popovers.electricity-fee-b')"
                            icon="AlertCircleIcon" 
                            class="cursor-pointer info-hover" 
                        />
                    </span>
                    <span>
                        {{ month_energy_payout_usd.formatMoney( 2, " ", " ")  }} $
                    </span>
                </div>

                <div class="d-flex justify-content-between" style="margin: 4px 0;">
                    <span class="text-muted font-weight-light">
                        {{$t('calculator.pool')}}
                          <feather-icon 
                                v-b-popover.hover.top="$t('popovers.pool')"
                                icon="AlertCircleIcon" 
                                class="cursor-pointer info-hover" 
                            />
                    </span>
                    {{ fee.formatMoney( User.currency === 'BTC' ? 5 : 2 ,' ', ' ') }} {{ User.getSymbol() }}
                </div>

                <div class="d-flex justify-content-between" style="margin: 4px 0;">
                    <span class="text-muted font-weight-light">
                            {{$t('calculator.service-fee')}}
                            <feather-icon 
                                v-b-popover.hover.top="$t('popovers.service-fee')"
                                icon="AlertCircleIcon" 
                                class="cursor-pointer info-hover" 
                            />
                    </span>
                    {{ service.formatMoney( User.currency === 'BTC' ? 5 : 2 ,' ', ' ') }} {{ User.getSymbol() }}
                </div>

                <hr v-if="bonus && !specOffer && !offer" style="margin-top: 16px; margin-bottom: 16px;">
                <div v-if="bonus && !specOffer && !offer" class="d-flex justify-content-between " style="margin: 4px 0; align-items: center;">
                    <div>
                        <b-img
                            src="~@/assets/images/icons/emoji/bonus.png"
                            fluid
                            alt="bonus"
                            style="width: 20px; height: 20px; margin-right: 3px; margin-left: 2px"
                        ></b-img>
                        <span class="text-muted font-weight-light">
                            {{$t('calculator.bonus-available')}}
                            <feather-icon 
                                v-b-popover.hover.top="$t('popovers.bonus')"
                                icon="AlertCircleIcon" 
                                class="cursor-pointer info-hover text-muted" 
                            />
                        </span>
                    </div>
                    <b-button variant="outline-primary" :class="{'disabled': is_bonus_active }" @click="activeBonus" >{{$t('calculator.btns.use-bonus')}}</b-button>
                </div>

                <hr v-if="specOffer" style="margin-top: 16px; margin-bottom: 16px;">
                <div v-if="specOffer" class="d-flex justify-content-between" style="margin: 4px 0;">
                    <span class="text-muted font-weight-light">
                        {{$t('calculator.there-special-offer')}}
                    </span>
                    <span style="text-align: end">
                        <span>{{ specOffer.title }}</span><br>
                        <b-badge variant="success" style="background-color: #3ED085; color: #FFF;">{{ specOffer.caption }}</b-badge>
                    </span>
                </div>

            </div>  
       </b-card-body>
    </b-card>
</template>

<script>

    import User from "@/modules/user/"
    import special from "@/modules/contents/special"
    import offers from "@/modules/contents/offers"

    export default {

        data() {
            return {
                User,

                bonus_percent: 0,
                is_bonus_active: false
            }
        },
        props: {
            
            total_ths: {
                type: Number,
                default: 0
            },
            current_price: {
                type: Number,
                default: 0
            },
            total_btc_price: {
                type: Number,
                default: 0
            },
            
            calcType: {
                type: Number, 
                default: 0 // history
            },
            
            diff_history: {
                type: Number,
                default: 10,
            },
            
            btc_percent: {
                type: Number,
                default: 10
            },
            
            diff_percent: {
                type: Number,
                default: 0
            },
            
            period: {
                type: Number,
                default: 12
            },
            
            market_price_percent: {
                type: Number,
                default: 10
            },

            bonus: {
                type: Object,
                default: null
            }
            
            
        },
        methods: {
            
            changeCurrency( event ) {
                event.stopPropagation();
                this.$router.push(`${this.$route.path}?act=currency`);
            },
            
            createContract( args ) {
                
                this.$emit("createContract");
                
            },

            activeBonus() {

                this.is_bonus_active = true
                this.bonus_percent = this.bonus.discount_percent

                this.$emit("bonusStateChanged", {
                    "is_bonus_active": this.is_bonus_active,
                    "bonus_percent": this.bonus_percent,
                });
            }
            
        },
        
        computed: {

            fee() {
                return (this.period_profit * 0.025) * this.market_price;
            },

            fee_btc() {
                return this.period_profit * 0.025;
            },
            
            service() {
                return (this.period_profit * 0.05) * this.market_price;
            },

            service_btc() {
                return this.period_profit * 0.05;
            },

            difficult() {
                
                let temp = parseInt(this.$user.contracts.values.D) * (this.diff_percent / 100);
                let growing = temp * this.period;
                let diff =parseInt(this.$user.contracts.values.D) + growing;
                
                if( diff <= 1000000000 ) {
                    diff = 1000000000;
                }
                                
                return parseInt(diff);
                
            },
            
            market_price() {
                // формула вычета сложно процента 
                if (User.currency === 'USD') {
                    return this.$blockchain.tickers.values[User.currency].last * (Math.pow((1 + this.market_price_percent / 100), this.period))
                } else if  (User.currency === 'BTC') {
                    return 1;
                } else {
                    return this.$blockchain.tickers.values[User.currency].last;
                }
            },

            period_profit() {
                // через средний рост сложности майнинга
                // return (86400 * 30 * this.period * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.total_ths )) / ( ((this.difficult + parseInt(this.$user.contracts.values.D)) / 2 ) * Math.pow(2, 32) );

                let profit = 0;
                let temp = parseInt(this.$user.contracts.values.D) * (this.diff_percent / 100);
                let diff = parseInt(this.$user.contracts.values.D);

                for (let i = 0; i < this.period; i++) {
                   profit += (86400 * 30 * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.total_ths )) / ( diff * Math.pow(2, 32) );

                   diff += temp;
                }

                return profit;
            },

            period_profit_with_bonus() {
                let profit = 0;
                let temp = parseInt(this.$user.contracts.values.D) * (this.diff_percent / 100);
                let diff = parseInt(this.$user.contracts.values.D);

                for (let i = 0; i < this.period; i++) {
                   profit += (86400 * 30 * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.total_ths_with_bonus )) / ( diff * Math.pow(2, 32) );

                   diff += temp;
                }

                return profit;
            },
            
            energy_payout() {
                let energy_btc = 0;

                let month_energy_usd = this.month_energy_payout_usd
                let usd_rate = this.$blockchain.tickers.values.USD.last; 

                for (let i = 0; i < this.period; i++) {
                    energy_btc += month_energy_usd / usd_rate;

                    usd_rate = usd_rate * (1 + this.market_price_percent / 100);
                }

                if (User.currency === "BTC") {
                    return energy_btc;
                } else if (User.currency === "USD") {
                    return month_energy_usd * this.period;
                } else {
                    return energy_btc * this.$blockchain.tickers.values[User.currency].last;
                }
            },

            energy_payout_btc() {
                let energy_btc = 0;

                let month_energy_usd = this.month_energy_payout_usd
                let usd_rate = this.$blockchain.tickers.values.USD.last; 

                for (let i = 0; i < this.period; i++) {
                    energy_btc += month_energy_usd / usd_rate;

                    usd_rate = usd_rate * (1 + this.market_price_percent / 100);
                }

                return energy_btc;
            },
                
            summary() {
                //v2
                return (this.period_profit * this.market_price) - this.energy_payout;

                //v1
                // return (this.period_profit * this.market_price) - this.energy_payout * this.period;
            },

            summary_with_bonus() {
                //v2
                return (this.period_profit_with_bonus * this.market_price) - this.energy_payout;

                //v1
                // return (this.period_profit_with_bonus * this.market_price) - (this.energy_payout * this.period);
            },

            month_energy_payout_usd() {
                // за месяц
                return this.total_ths * ( this.$user.contracts.values.E * 24 * 30 );
            },
            
            investments() {
                return this.total_btc_price * this.$blockchain.tickers.values[User.currency].last; 
            },
            
            percent() {
                return (( (this.summary - this.fee - this.service - this.investments)  / this.investments) * 100).toFixed(2);
            },

            percent_with_bonus() {
                return (( (this.summary_with_bonus - this.fee - this.service - this.investments)  / this.investments) * 100).toFixed(2);
            },

            total_ths_with_bonus() {
                let bonus_ths =  (this.total_ths * this.bonus_percent) / 100

                return this.total_ths + bonus_ths
            },

            specOffer() {
                let result = null;

                if( this.$route.query['with-offer'] ) {
                    result = special.items.find(item => item.id === parseInt(this.$route.query['with-offer']) );
                }  
                
                if( this.$route.query['with-offer'] && !result) {
                    result = special.individual_items.find(item => item.id === parseInt(this.$route.query['with-offer']) );
                }   
                
                return result;
            },

            offer() {
                if( this.$route.query['with-offer'] ) {
                    return offers.items.find(item => item.id === parseInt(this.$route.query['with-offer']) );
                }                
                
                return null;
            },
            
        },
        
        components: {

        },
        watch: {
        },
        mounted() {
           
        }

    }

</script>