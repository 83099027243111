<template>
    <div>
        <b-card-title class="font-weight-bolder">
            Контракт <b-link :to="{ name: 'user-contract-details', params: { id: contract.guuid } } ">№{{ contract.guuid }}</b-link> успешно создан
        </b-card-title> 

        <!--<hr class="mt-1 mb-1">-->

        <b-list-group flush class="no-hover mb-2">
            <b-list-group-item class="d-flex justify-content-between pl-0 pr-0" style="padding: 6px 0px;">
                <span class="text-muted">
                    Мощность
                </span>
                <span class="text-black ">
                    {{ contract.ths }} TH/s
                </span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between pl-0 pr-0" style="padding: 6px 0px;">
                <span class="text-muted">
                    Стоимость 1 TH/s
                </span>
                <span class="text-black ">
                    {{ current_price.formatMoney(2,' ',' ') }} $
                </span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between pl-0 pr-0" style="padding: 6px 0px;">
                <span class="text-muted">
                    Плата за электроэнергию в месяц
                </span>
                <span class="text-black ">
                    <!-- 2.5 $ -->
                    {{ energy_payout.formatMoney(2, " ", " ")  }} $
                </span>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between pl-0 pr-0" style="padding: 6px 0px;">
                <span class="text-muted">
                    Срок контракта
                </span>
                <span class="text-black">
                    {{ period }} {{ getPeriodHolder() }}
                </span>
            </b-list-group-item>
            <b-list-group-item v-if=" contract.invoice.type === 'card' " class="d-flex justify-content-between pl-0 pr-0">
                <span>
                    Сумма к оплате
                </span>
                <span class="text-black" style="font-size: 28px; font-weight: 600;">
                    {{ contract.investments.formatMoney(2,' ',' ') }} $
                </span>
            </b-list-group-item>
        </b-list-group>

        <!--<hr class="mt-1 mb-1">-->

        <div v-if=" contract.invoice.type === 'btc'">
            <div>
                <p class="text-muted" style="font-size: 13px; line-height: 20px;"> Для активации контракта переведите биткоин (BTC) любым удобным для Вас способом на указаный адрес в течение 15 минут. <br> Если в течении 15 минут платеж не будет произведен, то сумма к оплате изменится в зависимости от курса BTC.</p>
                <p class="text-muted" style="font-size: 13px; line-height: 20px;"> Оплата будет зачислена после первого подтверждения сети. </p>
                <p v-if="contract.is_anonymous" class="text-muted" style="font-size: 13px; line-height: 20px;"> Оплата контракта происходит на основании 
                    <b-link to="/documents?show=public-offer&type=1" target='_blank'>публичной оферты.</b-link></p>
                <p v-else class="text-muted" style="font-size: 13px; line-height: 20px;"> Оплата контракта происходит на основании договора сформированного в личном кабинете. </p>
            </div>
            <div class="data-form">
                <b-form-group>
                    <div class="d-flex justify-content-between">
                        <div style="flex-grow: 1;">
                            <span class="text-muted" style="font-size: 12px;">Сумма к оплате</span>
                            <p class="m-0">{{ contract.invoice.btc.decimalBtcMoney() }}</p>
                        </div>
                        <div class="mt-auto mb-auto">
                            <p class="m-0 text-muted">BTC</p>
                        </div>
                    </div>
                </b-form-group>
                
            </div>
            <div class="d-flex">

                <div style="margin-right: 26px;">
                    <b-form-group class="form-group-with-label">
                        <div ref="container" class="d-flex justify-content-between"> 
                            <div class="wordwrap">
                                <span class="text-muted" style="font-size: 12px;">Bitcoin адрес для оплаты</span>
                                <p style="font-size: 16px; font-weight: 500; margin: 0; word-break: break-all;">{{ contract.invoice.identificator }}</p>
                            </div>
                            <div 
                                v-ripple 
                                @click="copyBitcoinAddress"
                                style="margin: auto 12px; margin-right: 0;">
                                <feather-icon 
                                    icon="CopyIcon" 
                                    size="20" 
                                    class="text-muted cursor-pointer" 
                                />
                            </div>
                        </div>
                    </b-form-group>
                </div>

                <div>
                    <qrcode :value="`${contract.invoice.identificator}`" :options="{ width: 80, margin: 0 }"></qrcode>
                </div>

            </div>
            
            <b-row style="margin-top: 12px;">
                <b-col>
                   <b-button :to="{ name: 'user-wallet', query: { act: 'output', to: contract.invoice.identificator, amount: contract.invoice.btc.decimalBtcMoney() } }" replace size="lg" pill variant="primary" block style='padding-left:0; padding-right: 0;'>Оплатить из кошелька</b-button>
                </b-col>
                <b-col>
                   <b-button @click="$bvModal.hide('contract-details-modal')" size="lg" pill variant="gray" block style='padding-left:0; padding-right: 0;'>Оплатить потом</b-button>
                </b-col>
            </b-row>
            
        </div>

        <div v-else >
            <b-row>
                <b-col>
                    <b-button :href="payment_make_url" target="_blank" size="lg" pill variant="primary" block style='padding-left:0; padding-right: 0;'>Перейти к оплате</b-button>
                </b-col>
                <b-col v-if="!contract.is_anonymous">
                    <b-button :href="doc_pdf" target="_blank" size="lg" pill variant="outline-secondary" block  style='padding-left:0; padding-right: 0;'>Скачать договор</b-button>
                </b-col>
            </b-row>
        </div>

<!--        <b-row class='mt-1'>
            <b-col cols="12" class="d-flex">
                <b-link @click="$bvModal.hide('contract-details-modal')" class="text-center ml-auto mr-auto">Оплатить потом</b-link>
            </b-col>
        </b-row>-->

    </div>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    export default {

        data() {
            return {}
        },
        props: {
            contract: Object
        },
        methods: {
            
            
            copyBitcoinAddress( args ) {
                
                this.$copyText(this.contract.invoice.identificator, this.$refs.container );
                
                this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$t('toasts.titles.copy'),
                      text: this.$t('toasts.text.btc-address'),
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                    }
                });  
            },
            
            getPeriodHolder() {
                var placeholder = this.declOfNum( this.period, ['месяц', 'месяца', 'месяцев']);
                return `${placeholder}`;
            },
            
            declOfNum(number, titles) {  
                let cases = [2, 0, 1, 1, 1, 2];  
                if(number === 0) {
                    return titles[2];
                }
                return titles[ (number % 100 > 4 && number % 100 < 20 )? 2 : cases[(number%10<5)?number%10:5] ]; 
            },
            
        },
        computed: {
            
            current_price() {
                return this.contract.investments / this.contract.ths;
            },

            energy_payout_btc() {
                // электроэнергия за весь период (если выбрано отображение за период, в summary убрать умножение на period)
                // return ( this.total_ths * ( this.$user.contracts.values.E * 24 * 30 ) * this.period ) / this.$blockchain.tickers.values.USD.last; 

                // электроэнергия за месяц
                return ( this.contract.ths * ( this.$user.contracts.values.E * 24 * 30 ) ) / this.$blockchain.tickers.values.USD.last; 
            },
            
            energy_payout() {
                return this.energy_payout_btc * this.$blockchain.tickers.values.USD.last;
            },
            
            period() {
                return this.contract.period
            },

            doc_pdf() {
                return `${process.env.VUE_APP_BASE_URL}doc/${this.contract.doc}`
            },

            payment_make_url() {
                return `${process.env.VUE_APP_BASE_URL}payment-make/${this.contract.invoice.identificator}`
            }
            
        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>