<template>
    <div>
        
        <validation-observer ref="contract">
            <b-row>
                <b-col>
                    
                    <b-form-group class="form-group-with-label">
                        
                        <div class="d-flex justify-content-between" style="margin-bottom: 4px;">
                            <div style="flex-grow: 1;">
                                
                                <validation-provider
                                    #default="{ errors }"
                                    name="Investments"
                                    :rules="rules"
                                >
                                    <div v-if='errors[0]' class='text-danger'>
                                        <span  style="font-size: 12px; font-weight: 500;">
                                            {{$t('calculator.invest')}} 
                                        </span>
                                        <feather-icon icon='AlertCircleIcon' />
                                    </div>
                                    <span v-else class='text-muted' style="font-size: 12px;">{{$t('calculator.invest')}}</span> 
                                    
                                    <b-form-input
                                        type="number"
                                        :placeholder="$t('calculator.investment-amount')"
                                        v-model.number="total_price"
                                        @change="calcTotalThs()"
                                        :disabled="offer || specOffer ? true : false"
                                    />
                                </validation-provider>
                                
                            </div>
                            <div class="mt-auto mb-auto">
                                <b-dropdown
                                    :text="ticker"
                                    variant="muted"
                                    size="sm"
                                    right
                                  >
                                    
                                    <b-dropdown-item @click="changeCurrencyType('USD')">USD</b-dropdown-item>
                                    <b-dropdown-item @click="changeCurrencyType('BTC')">BTC</b-dropdown-item>

                                </b-dropdown>
                            </div>
                        </div>
                            
                        <vue-slider
                            v-model="total_price"
                            :min="sliderOptions.min"
                            :max="sliderOptions.max"
                            :dotSize="18"
                            :height="6"
                            :contained="false"
                            :interval="sliderOptions.step"
                            :duration="0.25"
                            :disabled="offer || specOffer ? true : false"
                            tooltip="none"
                            @change="calcTotalThs()"
                        />
                        
                    </b-form-group>
                    
                    <div v-if="ticker === 'USD'" class="d-flex justify-content-between mb-1">
                        <span class="text-muted" style="font-size: 12px;">100$</span>
                        <span class="text-muted" style="font-size: 12px;">10 {{$t('calculator.thousand')}} $</span>
                    </div>
                    <div v-else class="d-flex justify-content-between mb-1">
                        <span class="text-muted" style="font-size: 12px;">{{sliderOptions.min}} ₿</span>
                        <span class="text-muted" style="font-size: 12px;">{{sliderOptions.max}} ₿</span>
                    </div>
                    
                    <b-form-group class="form-group-with-label">
                        
                        <div class="d-flex justify-content-between" style="margin-bottom: 4px;">
                            <div style="flex-grow: 1;">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Investments"
                                    rules="required|integer"
                                >
                                    <div v-if='errors[0]' class='text-danger'>
                                        <span  style="font-size: 12px; font-weight: 500;">
                                            {{$t('calculator.contract-validity')}}
                                        </span>
                                        <feather-icon icon='AlertCircleIcon' />
                                    </div>
                                    <span v-else class='text-muted' style="font-size: 12px;">{{$t('calculator.contract-validity')}}</span>
                                    <b-form-input
                                        type="number"
                                        :placeholder="$t('calculator.contract-validity')"
                                        v-model.number="period"
                                        :min="12"
                                        :max="24"
                                        :formatter="periodFormatter"
                                        :disabled="offer || specOffer ? true : false"
                                        @input="calcTotalThs()"

                                    />
                                </validation-provider>
                            </div>
                            <div class="mt-auto mb-auto">
                                <span class="text-muted">{{$t('calculator.mon')}}</span>
                            </div>
                        </div>
                            
                        <vue-slider
                            v-model="period"
                            :min="24"
                            :max="36"
                            :dotSize="18"
                            :height="6"
                            :contained="false"
                            :interval="1"
                            :duration="0.25"
                            :disabled="offer || specOffer ? true : false"
                            tooltip="none"
                            @change="calcTotalThs()"
                        />
                    </b-form-group>
                    
                    <div class="d-flex justify-content-between mb-1">
                        <span class="text-muted" style="font-size: 12px;">24 {{$t('decl.months')}}</span>
                        <span class="text-muted" style="font-size: 12px;">36 {{$t('decl.months')}}</span>
                    </div>
                    
                    <hr class="mt-2 mb-2">
                    
                    <b-card-title>{{$t('contracts.type')}}</b-card-title>
                    
                    <b-row>
                        <b-col md="6" cols="12">
                            <div :class="type === 1 ? 'card-picker active' : 'card-picker'" @click="changeType(1)">
                                <div class="account-icon" style="width: 32px; height: 32px; background-size: 32px;" />
                                <p class="header">{{$t('contracts.official')}}</p>
                                <span class="text">{{$t('contracts.official-text')}}</span>
                            </div>
                        </b-col>
                        <b-col md="6" cols="12">
                            <div :class="type === 2 ? 'card-picker active' : 'card-picker'" @click="changeType(2)" :style="paymentType == 'card' ? 'opacity: 0.55; cursor: not-allowed !important; pointer-events: none;' : ''">
                                <div class="annon-icon" style="width: 32px; height: 32px; background-size: 32px;" />
                                <p class="header" :class="paymentType == 'card' ? 'text-danger' : ''">{{$t('contracts.anonymous')}}</p>
                                <span v-if="paymentType == 'btc'" class="text">{{$t('contracts.anonymous-text')}}</span>
                                <span v-else class="text text-danger">{{$t('contracts.pay-card-notif')}}</span>
                            </div>
                        </b-col>
                    </b-row>
                    
                    <form-owner v-if="type === 1" ref="owner" />
                    
                    <hr class="mt-2 mb-2">
                    
                    <b-card-title>{{$t('contracts.payment-methods')}}</b-card-title>
                    
                    <b-row>
                        <b-col md="6" cols="12" >
                            <div :class=" paymentType === 'card' ? 'card-picker active' : 'card-picker'" @click="changePaymentType('card')" :style="getRUBInvestments() > 64000 ? 'opacity: 0.55; cursor: not-allowed !important; pointer-events: none;' : ''">
                                <div :class="{
                                   'icon-bank-card' : paymentType !== 'card',
                                   'icon-bank-card-active' : paymentType === 'card'
                                }" style="width: 32px; height: 32px; background-size: 32px;" />
                                <p class="header" :class="getRUBInvestments() > 64000 ? 'text-danger' : ''">{{$t('contracts.bank-card')}}</p>
                                <span class='text text-danger' v-if="getRUBInvestments() > 64000">
                                    {{$t('contracts.maximum-amount-card')}}
                                    <br>
                                    {{$t('contracts.larger-amounts')}}
                                </span>
                                <span class='text' v-else>
                                    {{$t('contracts.with-cards-payments')}}
                                </span>
                            </div>
                                
                        </b-col>
                        <b-col md="6" cols="12">
                            <div :class=" paymentType === 'btc' ? 'card-picker active' : 'card-picker'" @click="changePaymentType('btc')">
                                <div :class="{
                                   'icon-btc' : paymentType !== 'btc',
                                   'icon-btc-active' : paymentType === 'btc'
                                }" style="width: 32px; height: 32px; background-size: 32px;" />
                                <p class="header">Bitcoin</p>
                                <span class="text">{{$t('contracts.using-btc')}}</span>
                            </div>
                        </b-col>
                    </b-row>
                    
                </b-col>
            </b-row>
        </validation-observer>
        
    </div>
</template>

<script>
    
    import User from "@/modules/user/"
    import Blockchain from "@/modules/blockchain"

    import offers from "@/modules/contents/offers"
    import special from "@/modules/contents/special"
     
    import VueSlider from 'vue-slider-component'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'

    import FormOwner from "./form-owner"

    import {
        required, min, max, digits, integer, minint, maxint
    } from '@/@core/utils/validations/validations'

    export default {

        data() {
            return {
                
                User,
                Blockchain,
        
                sliderOptions: {
                    step: 10,
                    max: 10000,
                    min: 100
                },
                
                ticker: "USD",
                
                price: User.contracts.values.P,
                price_with_discount: User.contracts.values.P,
                
                ths: 100,
                period: 24,
                investments: 320,
                total_price: 1000,
                max_ths: 10000,
                is_loading: false,
                
                btn_month_percent: 9.5,
                type: 2,
                paymentType: 'btc',
                
                showCardPaymentError: true,
                
                required,
                minint,
                maxint,
                min,
                max,
                digits,
                integer,
                
                rules: "required|minint:100|maxint:10000"
            }
        },
        
        props: [ 'params' ],
        
        methods: {
            
            summFormatter( args ) {
                
                let val = parseInt( args );
                
                if( val < 100 ) {
                    val = 100;
                } else if( val > 10000 ) {
                    val = 10000;
                } 
                
                return val;
                
            },
            
            periodFormatter( args ) {
                
                let val = parseInt( args );
                
                if( val < 24 ) {
                    val = 24;
                } else if( val > 36 ) {
                    val = 36;
                } 
                
                return val;
                
            },
            
            changeCurrencyType( value ) {
                if( this.ticker === value ) return;
                
                this.ticker = value;
                
                switch( value ) {
                    
                    case 'USD' : 
                        
                        this.total_price = this.total_price * this.$blockchain.tickers.values.USD.last;
                        
                        this.total_price = this.total_price.toFixed(0);

                        this.sliderOptions.step = 100;
                        this.sliderOptions.min = 100;
                        this.sliderOptions.max = 10000;

                        this.rules = "required|minint:"+this.sliderOptions.min+"|maxint:"+this.sliderOptions.max;
                        
                        break;
                        
                        
                    case 'BTC' : 
                        
                        this.total_price = this.total_price / this.$blockchain.tickers.values.USD.last;
                        
                        this.sliderOptions.step = 0.00001;
                        this.sliderOptions.min = parseFloat((100 / this.$blockchain.tickers.values.USD.last).toFixed(5));
                        this.sliderOptions.max = parseFloat((10000 / this.$blockchain.tickers.values.USD.last).toFixed(5));   

                        this.rules = "required|minint:"+this.sliderOptions.min+"|maxint:"+this.sliderOptions.max;
                    
                        break;
                                                    
                }
            },
            
            getFormData() {
                return new Promise(( resolve, reject) => {
                   
                    this.$refs.contract.validate().then( success => {
                        
                        console.log('проверка валидации при оформлении контракта form-calculator')
                        if(!success && this.type === 2) {
                            reject();
                            return;
                        }

                        let total_price = this.total_price
                        if (this.ticker === 'USD') {
                        } else {
                            total_price =  total_price * this.$blockchain.tickers.values.USD.last
                        }
                        
                        if( this.type === 1 ) {
                            this.$refs.owner.validate().then(success => {
                                if( success ) {
                                 
                                    if (this.$refs.owner.ownerType === 0 && this.$refs.owner.passport.length == 0) {
                                        reject();
                                    } else {
                                        resolve({
                                            type: this.type,
                                            summ: total_price,
                                            period: this.period,
                                            paymentType: this.paymentType,
                                            personalData: this.$refs.owner.getFormData()
                                        });
                                    }
                                } else {
                                    reject();
                                }
                            });
                        } else {
                            resolve({
                                type: this.type,
                                summ: total_price,
                                period: this.period,
                                paymentType: this.paymentType
                            });
                        }
                        
                    });    
                        
                });   
                
            },
            
            
            changePaymentType( newType ) {
                
                if ( this.getRUBInvestments() >= 64000 ) {
                    this.paymentType = 'btc';
                    return;
                }
                
                this.paymentType = newType;

                if (this.paymentType == 'card') this.type = 1
                
            },
            
            changeType( newType ) {

                if (this.paymentType == 'card') {
                    return;
                }
                
                this.type = parseInt(newType);
                
                               
                this.$emit("contract-type-changed", {
                    type: this.type
                });

                this.$emit("contractTypeChanged", {
                    type: this.type
                });
                
            },
            
            getPeriodProfit() {
                return (((86400 * 30 * this.period * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.ths )) / ( this.$user.contracts.values.D * Math.pow(2, 32) )) * this.$blockchain.tickers.values[User.currency].last ) - (this.ths * 2.5 * this.period) ;                
            },
            
            getDynamicPeriodProfit() {
                return ( this.btn_month_percent * this.getMounthProfit() * this.period) + this.getPeriodProfit();
            },
            
            getMounthProfit() {
                return (((86400 * 30 * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.ths )) / ( this.$user.contracts.values.D * Math.pow(2, 32) )) * this.$blockchain.tickers.values[User.currency].last ) - (this.ths * 2.5) ;                
            },
            
            getDailyProfit() {
                return (((86400 * this.$user.contracts.values.R * ( this.$user.contracts.values.H * this.ths )) / ( this.$user.contracts.values.D * Math.pow(2, 32) )) * this.$blockchain.tickers.values[User.currency].last );                
            },
            
            getInvestments() {
                
                var total_price = 0;
                
                if( this.ticker === 'USD' ) {
                    total_price = this.total_price;
                } else {
                    total_price = this.total_price * this.$blockchain.tickers.values.USD.last;
                } 
                
                return total_price;
                
            },
            
            
            calcTotalThs() {
                
                this.$nextTick(() => {    
                    if( this.ticker === 'USD' ) {
                        if ( this.total_price < 100 ) {
                            this.total_price = 100;
                        } else if( this.total_price > 10000 ) {
                            this.total_price = 10000;
                        } 
                    } else {
                        if ( this.total_price < this.sliderOptions.min ) {
                            this.total_price = this.sliderOptions.min;
                        } else if( this.total_price > this.sliderOptions.max ) {
                            this.total_price = this.sliderOptions.max;
                        } 
                    }
                    
                    
                    var total_price = 0;

                    if( this.ticker === 'USD' ) {
                        total_price = this.total_price;
                    } else {
                        total_price = Math.round(this.total_price * this.$blockchain.tickers.values.USD.last);
                    }    

                    // if( total_price < 100 ) {

                    //     total_price = 100;

                    //     if( this.ticker === 'USD' ) {
                    //         this.total_price = total_price;
                    //     } else {
                    //         this.total_price = total_price / this.$blockchain.tickers.values.USD.last;
                    //     }  

                    // } else if( total_price > 10000 ) {

                    //     total_price = 10000;

                    //     if( this.ticker === 'USD' ) {
                    //         this.total_price = total_price;
                    //     } else {
                    //         this.total_price = total_price / this.$blockchain.tickers.values.USD.last;
                    //     }  

                    // }

                    let power = (total_price / this.getCurrentPrice());

                    if( this.offer ) {
                        power = this.offer.ths;
                    }

                    if( this.specOffer ) {
                        power = this.specOffer.ths;
                    }

                    if( power > 10000 ) {
                        power = 10000;
                    }

                    if( this.period < 24 ) {
                        this.period = 24;
                    } else if( this.period > 36 ) {
                        this.period = 36;
                    }


                    this.ths = power;


                    if( this.$route.query.summ || this.$route.query.period ) {
                        this.$router.replace({
                            query: {
                                period: this.period,
                                summ: total_price
                            }
                        });
                    }

                    if( this.getInvestments() >= 20000 ) {
                        this.paymentType = "btc";
                    }


                    this.$emit("dataChanged", {
                        "period" : this.period,
                        "period_profit" : this.getPeriodProfit(),
                        "total_ths": this.ths,
                        "current_price" : this.getCurrentPrice(),
                        "btc" : this.getTotalBTCPrice(),
                        "investments": this.getInvestments()
                    });
                    
                });
                
            },
            
            getSizeDiscount() {
                
                let discountForSize = 0;
                
                if( this.ths > 1 ) {
                    discountForSize = 2500 * (this.power / 10000);
                }
                
                return (discountForSize / 10000) * 100;
                
            },
            
            getPeriodDiscount() {
                
                let discount = 0;
                
                if( this.form.period > 24 ) {
                    discount = ( this.period - 24 ) * 0.5;
                }
                                
                return (discount / this.price) * 100 ;
                
            },
            
            getTotalBTCPrice() {
                
                if( this.ticker === 'USD' ) {
                   return this.total_price / this.$blockchain.tickers.values.USD.last;
                } else {
                    return this.total_price;
                }
                
            },
            
            getCurrentPrice() {
                
                let price = this.price * 12;   
                let perice_per_month = this.price;
                               
                for(var i = 13 ; i <= this.period; i++ ) {
                    perice_per_month = perice_per_month * 0.9;
                    price = (price + perice_per_month);
                }     
                
                this.price_with_discount = price;
                
                return price;
                
            },
            
            getTotalPrice() {
                this.total_price = this.ths * this.getCurrentPrice();
            },
            
            getPeriodHolder() {
                var placeholder = this.declOfNum( this.period, ['месяц', 'месяца', 'месяцев']);
                return `${placeholder}`;
            },
            
            declOfNum(number, titles) {  
                let cases = [2, 0, 1, 1, 1, 2];  
                if(number === 0) {
                    return titles[2];
                }
                return titles[ (number % 100 > 4 && number % 100 < 20 )? 2 : cases[(number%10<5)?number%10:5] ]; 
            },

            getRUBInvestments() {
                
                var total_price = 0;
                
                if( this.ticker === 'USD' ) {
                    total_price = this.total_price / this.$blockchain.tickers.values.USD.last * this.$blockchain.tickers.values.RUB.last;
                } else {
                    total_price = this.total_price * this.$blockchain.tickers.values.RUB.last;
                } 
                
                return total_price;

            }
        },
        
        computed: {
            
            offer() {
                if( this.$route.query['with-offer'] ) {
                    return offers.items.find(item => item.id === parseInt(this.$route.query['with-offer']) );
                }                
                
                return null;
            },

            specOffer() {
                let result = null;

                if( this.$route.query['with-offer'] ) {
                    result = special.items.find(item => item.id === parseInt(this.$route.query['with-offer']) );
                }  
                
                if( this.$route.query['with-offer'] && !result) {
                    result = special.individual_items.find(item => item.id === parseInt(this.$route.query['with-offer']) );
                }   
                
                return result;
            },
            
        },
        
        components: {
            VueSlider,
            ValidationProvider,
            ValidationObserver,
            FormOwner
        },
        watch: {
            
            "period" : function( val ) {
                
                if( val < 12 ) {
                    this.period = 12;
                } else if( val > 36 ) {
                    this.period = 36;
                }
                
                this.calcTotalThs();
                
            },
            
            "User.currency" : function() {
                this.$emit("dataChanged", {
                    "period" : this.period,
                    "period_profit" : this.getPeriodProfit(),
                    "total_ths": this.ths,
                    "current_price" : this.getCurrentPrice(),
                    "btc" : this.getTotalBTCPrice(),
                    "investments": this.getInvestments()
                });
            },

            "Blockchain.tickers.values.USD.last" : function() {
                this.$emit("dataChanged", {
                    "period" : this.period,
                    "period_profit" : this.getPeriodProfit(),
                    "total_ths": this.ths,
                    "current_price" : this.getCurrentPrice(),
                    "btc" : this.getTotalBTCPrice(),
                    "investments": this.getInvestments()
                });
            },

            "total_price" : function() {

                if (this.paymentType === 'card' && this.getRUBInvestments() >= 64000 ) {
                   this.changePaymentType('btc');
                }
                
            }

        },
        mounted() {
            
            if( this.$route.query.period ) {
                this.period = this.$route.query.period;
            }
            
            if( this.$route.query.summ ) {
                this.total_price = this.$route.query.summ;
            }
            
            if( this.offer ) {
                this.period = this.offer.period;
                this.total_price = this.offer.price_amount;
            }

            if( this.specOffer ) {
                this.period = this.specOffer.period;
                this.total_price = this.specOffer.price_amount;
            }
            
            this.calcTotalThs();
            
        }

    }

</script>

<style lang="scss" >
    @import '@core/scss/vue/libs/vue-slider.scss';
</style>