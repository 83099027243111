<template>

    <validation-observer ref="personal">
                
        <b-form @submit.prevent="save" class="data-form"> 

            <b-row>

                <b-col md="12">
                    <b-card no-body class="m-0">

                        <b-card-header class="pl-0" style="flex-direction: column; align-items: flex-start;">
                            <b-card-title class="text-black" style="font-size: 14px;">{{$t('forms.main-data')}}</b-card-title>

                            <b-card-text class="text-muted mt-2">{{$t('forms.notification-validate-data')}}</b-card-text>
                        </b-card-header>

                        <b-card-body class="p-0">

                            <b-row>

                                <b-col md="4">
                                    <b-form-group >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="LastName"
                                            rules="required"
                                          >
                                            <span class="text-muted" style="font-size: 12px;">{{$t('forms.labels.last-name')}}</span>
                                            <b-form-input
                                              :placeholder="$t('forms.placeholders.last-name')"
                                              v-model="personal.last_name"
                                            />
                                            <small class="text-danger font-small-1">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col md="4">
                                    <b-form-group>
                                        <validation-provider
                                            #default="{ errors }"
                                            name="FirstName"
                                            rules="required"
                                          >
                                            <span class="text-muted" style="font-size: 12px;">{{$t('forms.labels.first-name')}}</span>
                                            <b-form-input
                                            :placeholder="$t('forms.placeholders.first-name')"
                                              v-model="personal.first_name"
                                            />
                                            <small class="text-danger font-small-1">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col md="4">
                                    <b-form-group>
                                        <validation-provider
                                            #default="{ errors }"
                                            name="MiddleName"
                                            rules="required"
                                          >
                                            <span class="text-muted" style="font-size: 12px;">{{$t('forms.labels.middle-name')}}</span>
                                            <b-form-input
                                              :placeholder="$t('forms.placeholders.middle-name')"
                                              v-model="personal.middle_name"
                                            />
                                            <small class="text-danger font-small-1">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                            </b-row>

                            <b-row>

                                <b-col md="4">
                                    <b-form-group>
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Gender"
                                            rules="required"
                                          >
                                            <span class="text-muted" style="font-size: 12px;">{{$t('forms.labels.gender')}}</span>
                                            <v-select
                                              :options="genders"
                                              v-model="personal.gender"
                                              label="name"
                                            />
                                            <small class="text-danger font-small-1">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col md="4">
                                    <b-form-group>
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Bday"
                                            rules="required"
                                          >
                                            <span class="text-muted" style="font-size: 12px;">{{$t('forms.labels.birthday')}}</span>
                                            <cleave
                                                v-model="personal.birthday"
                                                class="form-control"
                                                :raw="false"
                                                :options="date"
                                                :placeholder="$t('forms.placeholders.date')"
                                            />
                                            <small class="text-danger font-small-1">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col md="4">
                                    <b-form-group>
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Country"
                                            rules="required"
                                          >
                                            <span class="text-muted" style="font-size: 12px;">{{$t('forms.labels.country')}}</span>
                                            <v-select
                                              :options="countries"
                                              v-model="personal.country"
                                              label="name"
                                            />
                                            <small class="text-danger font-small-1">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                            </b-row>

                            <b-row v-if="personal.country.id == 181">
                                 <b-col md="4">
                                    <b-form-group>
                                         <validation-provider
                                            #default="{ errors }"
                                            name="Inn"
                                            rules="required"
                                          >
                                            <span class="text-muted" style="font-size: 12px;">{{$t('forms.labels.inn')}}</span>

                                            <cleave
                                                v-model="personal.inn"
                                                class="form-control"
                                                :raw="false"
                                                :options="inn"
                                                placeholder="XXXXXXXXXXXX"
                                            />

                                            <small class="text-danger font-small-1">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                        </b-card-body>

                    </b-card>

                    <hr class="mt-2 mb-2">
                    
                    <b-card no-body class="m-0">

                        <b-card-header class="pl-0">
                            <b-card-title class="text-black" style="font-size: 14px;">{{$t('forms.passport-data')}}</b-card-title>
                        </b-card-header>

                        <b-card-body class="p-0">

                            <b-row>

                                <b-col md="3" v-if="personal.country.id == 181">
                                    <b-form-group>
                                        <validation-provider
                                            #default="{ errors }"
                                            name="PassportSeries"
                                            rules="required"
                                          >
                                            
                                            <span class="text-muted" style="font-size: 12px;">{{$t('forms.labels.passport-series')}}</span>

                                            <cleave
                                                v-model="personal.passport_series"
                                                class="form-control"
                                                :raw="false"
                                                :options="series"
                                                placeholder="XX XX"
                                            />

                                            <small class="text-danger font-small-1">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col md="3" v-if="personal.country.id == 181">
                                    <b-form-group >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="PassportNumber"
                                            rules="required"
                                          >
                                            <span class="text-muted" style="font-size: 12px;">{{$t('forms.labels.passport-number')}}</span>

                                            <cleave
                                                id="date"
                                                v-model="personal.passport_number"
                                                class="form-control"
                                                :raw="false"
                                                :options="number"
                                                placeholder="XXXXXX"
                                            />
                                            <small class="text-danger font-small-1">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col md="3" v-else>
                                    <b-form-group >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="PassportNumber"
                                            rules="required"
                                          >
                                            <span class="text-muted" style="font-size: 12px;">{{$t('forms.labels.series-and-number')}}</span>

                                            <cleave
                                                id="date"
                                                v-model="personal.passport_number"
                                                class="form-control"
                                                :raw="false"
                                                :options="international_number"
                                                placeholder="XXXXXXXXXXXXX"
                                            />
                                            <small class="text-danger font-small-1">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col md="3">
                                    <b-form-group >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="PassportDate"
                                            rules="required"
                                          >
                                            <span class="text-muted" style="font-size: 12px;">{{$t('forms.labels.passport-date-of-issue')}}</span>
                                            <cleave
                                                v-model="personal.passport_date_of_issue"
                                                class="form-control"
                                                :raw="false"
                                                :options="date"
                                                :placeholder="$t('forms.placeholders.date')"
                                            />
                                            <small class="text-danger font-small-1">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col md="3" v-if="personal.country.id == 181">
                                    <b-form-group >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="PassportCode"
                                            rules="required"
                                          >
                                            <span class="text-muted" style="font-size: 12px;">{{$t('forms.labels.passport-department-code')}}</span>
                                            <cleave
                                                id="date"
                                                v-model="personal.passport_department_code"
                                                class="form-control"
                                                :raw="false"
                                                :options="code"
                                                placeholder="XXX-XXX"
                                            />

                                            <small class="text-danger font-small-1">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12">

                                    <b-form-group >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="PassportOwner"
                                            rules="required"
                                          >
                                            <span class="text-muted" style="font-size: 12px;">{{$t('forms.labels.passport-issued-by')}}</span>
                                            <b-form-textarea
                                                v-model="personal.passport_issued_by"
                                                :placeholder="$t('forms.placeholders.passport-issued-by')"
                                                max-rows="2"
                                                rows="1"
                                                style="height: auto; min-height: 22px; padding: 0 !important;"
                                            />

                                            <small class="text-danger font-small-1">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>

                                </b-col>

                            </b-row>

                            <b-row>

                                <b-col v-if="passport.length === 0" @click="preferMediaPickerAction" class="d-flex justify-content-center align-items-center flex-nowrap bg-gray" style="padding: 12px; height: 175px; cursor: pointer; border-radius: 4px; margin: 0 12px; flex-direction: column;">
                                    <span class="text-muted">{{$t('forms.add-photo')}}</span>
                                    <small v-if="passport_media_empty" class="text-danger font-small-1 mt-2">{{$t('required')}}</small>
                                </b-col>
                                
                                <b-col v-else @click="preferMediaPickerAction" class="d-flex justify-content-start align-items-center flex-nowrap bg-gray" style="padding: 12px; height: 175px; cursor: pointer; border-radius: 4px; margin: 0 12px; overflow-y: hidden; overflow-x: scroll;">
                                    <div v-for="(media, index) in passport" :key="index" @click="$event.stopPropagation()" :style="getMediaPlaceholderStyle(media)">
                                        
                                        <div @click="prefereMediaDeleteAction( $event, index )" class="bg-white" style="position: absolute; text-align: center; line-height: 30px; pointer: cursor; right: -10px; top: -10px; width: 32px; height: 32px; border-radius: 50%;">
                                            <feather-icon icon="XIcon" size="20" color="gray" />
                                        </div>
                                        
                                        <b-progress 
                                            v-if="media.id < 0"
                                            :value="media.progress.loaded" 
                                            :max="media.progress.total" 
                                            show-progress 
                                            animated
                                            style="width: 100%"
                                        ></b-progress>
                                        
                                    </div>
                                </b-col>
                                    
                                </b-row>

                        </b-card-body>

                    </b-card>

                    <hr class="mt-2 mb-2">

                    <b-card no-body class="m-0">

                        <b-card-header class="pl-0">
                            <b-card-title class="text-black" style="font-size: 14px;">{{$t('forms.contact-data')}}</b-card-title>
                        </b-card-header>

                        <b-card-body class="p-0">

                            <b-row>
                                <b-col md="6">
                                    <b-form-group>
                                        <validation-provider
                                            #default="{ errors }"
                                            name="PhoneNumber"
                                            rules="required"
                                          >
                                            <span class="text-muted" style="font-size: 12px;">{{$t('forms.labels.phone')}}</span>
                                            <cleave
                                                id="date"
                                                v-model="personal.phone_number"
                                                class="form-control"
                                                :raw="false"
                                                :options="phone"
                                                placeholder="XXX-XXX"
                                            />

                                            <small class="text-danger font-small-1">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col md="6">
                                    <b-form-group>
                                        <span class="text-muted" style="font-size: 12px;">{{$t('forms.labels.email')}}</span>
                                        <b-form-input
                                            :value="$user.self.email"
                                            readonly
                                        />
                                    </b-form-group>
                                </b-col>

                            </b-row>

                        </b-card-body>

                    </b-card>
                    
                    <hr class="mt-2 mb-2">

                </b-col>

            </b-row>

            <b-row v-show="!isModalView">
                <b-col cols="12" md="6" lg="6">
                    <p class="p-0 m-0">{{$t('forms.submitting-information')}} <b-link :to="{ name: 'user-documents', params: {type: 'privacy'}}">{{$t('forms.policy')}}</b-link> {{$t('forms.and')}} <b-link :to="{ name: 'user-documents', params: {type: 'public-offer'}}">{{$t('forms.offer')}}</b-link>.</p>
                </b-col>
                <b-col cols="12" md="6" lg="6" class="d-flex justify-content-end align-content-end align-items-end">
                    <showAt breakpoint="mediumAndAbove">
                        <b-button @click="save" variant="primary" style="height: 56px; font-size: 16px; border-radius: 16px;" >{{$t('forms.btns.save-info')}}</b-button>
                    </showAt>
                    <showAt breakpoint="small">
                        <b-button @click="save" variant="primary" block style="margin-top: 24px; height: 56px; font-size: 16px; border-radius: 16px;" >{{$t('forms.btns.save-info')}}</b-button>
                    </showAt>
                </b-col>
            </b-row>

            <b-row v-if="isModalView" class="mb-2">

                <b-col>
                    <b-form-checkbox
                        id="checkbox-1"
                        v-model="is_need_save"
                        value="is_can_save"
                      >
                        <div style="margin-left: 8px; margin-top: 1px;">{{$t('forms.save-data-from-other-contracts')}}</div>
                    </b-form-checkbox>
                </b-col>

            </b-row>

        </b-form>

    </validation-observer>   
    
</template>

<script>
    
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import Cache from "@/modules/cache/"
    import User from "@/modules/user/"
    import vSelect from 'vue-select'
    import Cleave from 'vue-cleave-component'
    import { pickFile } from 'js-pick-file';
    import $utils from "@/modules/utils/"
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    import {
        required
    } from '@validations'

    export default {

        data() {
            return {
                required,
                countries: [],
                personal: {
                    
                    last_name: "",
                    first_name: "",
                    middle_name: "",
                    
                    gender: { 
                        id: 1, 
                        name: this.$i18n.locale === 'ru' ? "Мужской" : "Female" 
                    },
                    
                    country: {
                        id: 181,
                        name: "Россия",
                        phonecode: 7
                    },
                    
                    birthday: "",
                    
                    passport_series: "",
                    passport_number: "",
                    passport_date_of_issue: "",
                    passport_department_code: "",
                    passport_issued_by: "",
                    
                    phone_number: "",
                    
                    inn: ""
                },
                
                is_need_save: "",
                
                genders: [
                    { id: 1, name: this.$i18n.locale === 'ru' ? "Мужской" : "Female" },
                    { id: 2, name: this.$i18n.locale === 'ru' ? "Женский" : "Male" }
                ],
                date: {
                    date: true,
                    delimiter: '.',
                    datePattern: ['d', 'm', 'Y'],
                },
                series: {
                    numericOnly: true,
                    blocks:[2,2],
                    numeralThousandsGroupStyle: 'none'
                },
                number: {
                    numericOnly: true,
                    blocks:[6],
                    numeralThousandsGroupStyle: 'none'
                },
                international_number: {
                    numericOnly: false,
                    blocks:[30],
                    numeralThousandsGroupStyle: 'none'
                },
                code: {
                    numericOnly: true,
                    delimiter: '-',
                    blocks:[3,3]
                },
                inn: {
                    numericOnly: true,
                    blocks:[12],
                    numeralThousandsGroupStyle: 'none'
                },

                passport: [],

                passport_media_empty: false
                
            }
        },
        props: {
            isModalView: {
                type: Boolean,
                default: false
            },
            doneButtonText: {
                type: String,
                default: "Готово"
            }
        },
        methods: {

            validate() {
                if (this.passport.length == 0) {
                    this.passport_media_empty = true
                } else this.passport_media_empty = false

                return this.$refs.personal.validate();
            },
    
            submit() {
                this.$refs.personal.validate().then(success => {

                    if (this.passport.length == 0) {
                        this.passport_media_empty = true
                        return;
                    } else this.passport_media_empty = false

                    if( success ) {
                        if( this.is_need_save === "is_can_save" ) {
                            
                            var params = Object.create(this.personal);
                            var passport =  this.passport.map( media => media.id );
                            // params['gender'] = params['gender']['id'];
                            // params['country'] = params['country']['id'];

                            this.$request.post("user.personal.update", {
                                'inn': params['inn'],
                                'phone_number': params['phone_number'],
                                'passport_issued_by': params['passport_issued_by'],
                                'passport_department_code': params['passport_department_code'],
                                'passport_date_of_issue': params['passport_date_of_issue'],
                                'passport_number': params['passport_number'],
                                'passport_series': params['passport_series'],
                                'birthday': params['birthday'],
                                'middle_name': params['middle_name'],
                                'last_name': params['last_name'],
                                'first_name': params['first_name'],
                                'gender': params['gender']['id'],
                                'country':params['country']['id'],
                                'passport': passport
                            }).then( result => {
                                User.get();
                            }).catch(err => {

                            });
                                
                            } 
                            
                            this.$emit("data-changed", {
                                personal: this.personal,
                                passport: this.passport
                            });
                        
                        }
                });
                
            },
        
            save() {
                
                if( this.isModalView ) {
                    this.submit();
                    return;
                }
                this.$refs.personal.validate().then(success => {
                    
                    if( !success ) return;
                    if (this.passport.length == 0) {
                        this.passport_media_empty = true
                        return;
                    } else this.passport_media_empty = false
                    
                    var params = Object.create(this.personal);
                    let passport =  this.passport.map( media => media.id );
                //   params['gender'] = params['gender']['id'];
                //   params['country'] = params['country']['id'];
                    
                    this.$request.post("user.personal.update", {
                        'inn': params['inn'],
                        'phone_number': params['phone_number'],
                        'passport_issued_by': params['passport_issued_by'],
                        'passport_department_code': params['passport_department_code'],
                        'passport_date_of_issue': params['passport_date_of_issue'],
                        'passport_number': params['passport_number'],
                        'passport_series': params['passport_series'],
                        'birthday': params['birthday'],
                        'middle_name': params['middle_name'],
                        'last_name': params['last_name'],
                        'first_name': params['first_name'],
                        'gender': params['gender']['id'],
                        'country':params['country']['id'],
                        'passport': passport
                    }).then( result => {
                        User.get();
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                text: this.$t('toasts.titles.save-individual-data'),
                                icon: 'CheckCircleIcon',
                                variant: 'success',
                            }
                        });
                    }).catch(err => {
                        
                    });
                    
                });
                 
            },

            get_countries() {
                
                this.countries = [];
                
                Cache.countries.forEach(item => {
                    this.countries.push({
                        id: item.id,
                        name: item.name,
                        phonecode: item.phonecode
                    });
                });
                
            },
            
            getMediaPlaceholderStyle( media ) {
                
                return {
                    position: "relative",
                    display: 'flex',
                    alignItems: "center",
                    width: "125px",
                    minWidth: "125px",
                    height: "125px",
                    backgroundImage: `url(${media.thumb || media.urls.thumb})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    borderRadius: "12px",
                    margin: "0 8px",
                    padding: "0 8px"
                }
                
            },
            
            prefereMediaDeleteAction(args, index ) {
                
                this.passport = [];
                // this.passport.splice( index, 1 );
                // args.stopPropagation()
                
            },

            preferMediaPickerAction( args ) {

                let options = { 
                    accept: '.jpg, .jpeg, .png', 
                    multiple: true 
                }
                
                pickFile(options).then(files => {
                     files.forEach(file => {
                         
                        $utils.preferBeforeUploadAction( file ).then( data => {
                            
                            let object = {
                                id: -1,
                                type: data.type,
                                file: file,
                                fileName: file.name,
                                size: file.size,
                                extension: file.name.split('.').pop(),
                                duration: data.duration,
                                thumb: data.thumb,
                                placeholder: data.placeholder,
                                width: data.width,
                                height: data.height,
                                progress: {
                                    loaded: 0,
                                    total: 0
                                }
                                
                            }
                            
                            this.passport = [];
                            this.passport.push(object);
                            
                            this.$request.transfer("media.upload", {
                                type: data.type,
                                width: data.width,
                                height: data.height
                            }, file, ( progress ) => {
                                object.progress.total = progress.total;
                                object.progress.loaded = progress.loaded;
                            }).then( rsp => {
                                this.$set(object, "id", rsp.upload_id )
//                                object.id = rsp.upload_id;
                            });
                            
                        }); 
                         
                         
                     });
                });
                
            },


        },
        
        computed: {
            
            
            phone() {
                
                let prefix = `+${this.personal.country.phonecode}`;
                                
                return {
                    numericOnly: true,
                    delimiters: [ ' (', ') ', '-', '-' ],
                    prefix: prefix,
                    blocks: [ prefix.length , 3, 3, 2, 2 ]
                }
                
            },
            
            // countries() {
                
            //     let options = [];
            //     Cache.countries.forEach(item => {
            //         options.push({
            //             id: item.id,
            //             name: item.name,
            //             phonecode: item.phonecode
            //         });
            //     });
                
            //     return options;
                
            // }
            
        },
        
        components: {
            ValidationProvider,
            ValidationObserver,
            vSelect,
            Cleave
        },
        watch: {

            personal: {
                deep: true,
                handler(bef) {
                    this.$emit('changePersonal', this.personal, this.passport)
                }

            },
            passport: {
                deep: true,
                handler(bef) {
                    this.$emit('changePersonal', this.personal, this.passport)
                }
            }

        },
        mounted() {
            if( User.self.personal ) {
                Object.keys(User.self.personal).forEach(key => {
                    if( this.personal.hasOwnProperty(key) ) {
                        if (key == 'gender') {
                            if (User.self.personal[key]['id'] == 1) {
                                if (this.$i18n.locale == 'ru') User.self.personal[key]['name'] = 'Мужской';
                                else User.self.personal[key]['name'] = 'Female';
                            } else {
                                if (this.$i18n.locale == 'ru') User.self.personal[key]['name'] = 'Женский';
                                else User.self.personal[key]['name'] = 'Male';
                            }
                        }
                        this.personal[key] = User.self.personal[key];
                    }
                });
            }
            this.passport =  User.self.passport_media;

            setTimeout(() => {
                this.get_countries()
            }, 1000 );

        }

    }

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
 